.file-upload-component {
    height: 210px;
    border-radius: 12px;
    border: 2px dashed var(--Primary-Dark-Gray, #161618);
    /* background-color: var(--Gray-100, #F4F5F5); */
}

.file-upload-component.aspect-ratio-tall {
    height: 460px;
    width: 360px;
    max-height: 100%;
    max-width: 100%;
}

.file-upload-component.aspect-ratio-wide {
    height: 360px;
    width: 1280px;
    max-height: 100%;
    max-width: 100%;
}

.file-upload-component.aspect-ratio-square {
    height: 640px;
    width: 640px;
    max-height: 100%;
    max-width: 100%;
}

.text-on-image {
    background: rgb(0 0 0 / 20%);
    color: white;
}
.file-listing {
    display: flex;
    gap: 8px;
    flex-direction: column;
}

.file-card {
    display: flex;
    padding: 20px 40px;
    align-items: center;
    gap: 40px;
    align-self: stretch;
    border-radius: 12px;
    background-color: var(--Primary-Dark-Gray, #161618);
    color: var(--Primary-White, #FFFFFF);
}

.file-card-lite {
    background: var(--Gray-100, #F4F5F5) !important;
    color: var(--Primary-Dark-Gray, #161618) !important;
}

.file-listing-lite.file-card {
    background: var(--Gray-100, #F4F5F5);
}

.file-card__thumbnail {
    width: 110px;
    height: 80px;
    border-radius: 12px;
    background-size: cover;
    background-repeat: no-repeat;

}

.file-card__name {
    display: flex;
    padding-left: 12px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    flex: 1 0 0;
    align-self: stretch
}

@media(max-width: 500px) {
    .file-card {
        gap: 10px;
        padding: 20px;
    }
}