.alert {
    display: flex;
    padding: 16px 28px;
    align-items: center;
    gap: 40px;
    align-self: stretch;

}
.alert.warning{
    border-radius: 12px;
    background: var(--Primary-Yellow, #FDFD43);
    color: var(--Primary-Dark-Gray, #161618);
}