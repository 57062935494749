.blog-container {
    background-color: white;
    height: 100vh;
    display: flex;
}

.blog-header {
    background-color: var(--Primary-Dark-Gray);
    padding: 150px 0 150px 0;
    text-align: center;
}

.blog-header h1 {
    color: white;
    max-width: 1024px;
    margin: auto;

}

.blog-listing {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    gap: 80px;
    margin: 72px 68px 72px 68px;
    flex-wrap: wrap;
}

.blog-footer {
    background-color: var(--Primary-Yellow);
    color: var(--Primary-Dark-Gray, #161618);
    font-family: "Oswald";
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    /* 57.6px */
    padding: 68px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
}

.blog-footer div {
    /*flex-grow: 1;*/
}

.blog-footer button {
    display: flex;
    padding: var(--Font-Size-S, 16px) 30px;
    justify-content: center;
    align-items: center;
    gap: var(--Font-Size-XS, 10px);
    border-radius: 70px;
    background: var(--Primary-Dark-Gray, #161618);
    color: var(--Primary-White, #FFF);
    font-family: "Oswald";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    /* 24px */
    margin-top: 68px;
}

.blog-post-card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /*gap: var(--Font-Size-M, 20px);*/
    cursor: pointer;
    width: 360px;

}

.blog-post-card img {
    border-radius: var(--Font-Size-S, 16px);
    margin-bottom: 20px;
}

.blog-post-card .blog-title {
    color: #000;
    /* Desktop/Sans/L */
    font-family: var(--Font-Family-Sans, "Oswald");
    font-size: var(--Font-Size-L, 24px);
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    /* 28.8px */
}

.blog-post-card .blog-published-at {
    color: #000;

    /* Desktop/Body/Regular */
    font-family: var(--Font-Family-Sans, "Oswald");
    font-size: var(--Font-Size-S, 16px);
    font-style: normal;
    font-weight: 400;
    line-height: 144%;
    /* 23.04px */
}


.blog-post-header {
    color: var(--Primary-Dark-Gray, #161618);
    padding: 150px 0 60px 0;
    max-width: 1024px;
    margin: auto;
    text-align: center;
}

.blog-post-header h1 {
    color: var(--Primary-Dark-Gray, #161618);
    max-width: 1024px;
    margin: auto;
}

.blog-content {
    flex-grow: 1;
    padding-top: 60px;
    padding: 60px;
    font-size: 24px;
}

.blog-content p {
    font-size: 24px;
}

.blog-container .hline {
    display: block;
    height: 8px;
    border-top: 8px solid var(--Primary-Dark-Gray, #161618);
    max-width: 1024px;
    margin: auto;
    width: 1024px;

}