.project_card{
    display: flex;
    width: 424px;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 12px;
    border: 1px solid var(--Primary-Dark-Gray, #161618);
}
.project_card_image{
    width: 424px;
    height: 304px;
    border-radius: 12px 12px 0px 0px;
    background: var(--Secondary-Medium-Gray, #202124);
}
.project_card_content{
    display: flex;
    height: 236px;
    padding: 28px 28px 52px 28px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    background: var(--Primary-Dark-Gray, #161618);
    border-radius: 0px 0px 12px 12px;
}