/**
* @description Color variables from Figma https://www.figma.com/file/YLYDIeT1jdGkFrhn72taON/Official-AI%3A-Website?node-id=1448%3A89881&mode=dev
 */
:root {
    --Primary-Dark-Gray: #161618;
    --Secondary-Medium-Gray: #202124;
    --Gray-900: #1D1D1D;
    --Gray-700: #2B2D31;
    --Gray-600: #37393E;
    --Gray-500: #46484E;
    --Primary-White: #FFF;
    --Gray-100: #F4F5F5;
    --Gray-200: #E9EAEC;
    --Gray-300: #E1E2E4;
    --Gray-400: #D4D5D8;
    --Primary-Yellow:  #FDFD43;
    --Secondary-Pink: #F378FF;
    --Secondary-Blue: #2937FF;
    --Secondary-Red:   #FD5939;
}