.nav_header {
    background: transparent;
    position: fixed;
    display: flex;
    width: 100%;
    padding: 20px 60px;
    align-items: center;
    z-index: 50;
    top: 0;
    gap: 42px;
    height: 100px;
}

.nav_header.minimal_header {
    justify-content: center;
}

.nav_header.scroll_background {
    background: linear-gradient(180deg, #141414 0%, rgba(20, 20, 20, 0.30) 100%);
}

.nav_links {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1.25em;
    flex: 1 0 0;
    text-wrap: nowrap;
}

.nav_actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 32px;
    flex: 1 0 0;
    text-wrap: nowrap;
}

.nav_header.lite .menu_button {
    color: white;
}

.nav_header.dark .nav_links a,
.nav_header.dark .nav_links button,
.nav_header.dark .nav_actions a,
.nav_header.dark .nav_actions button {
    color: var(--Primary-Dark-Gray);
    font-style: normal;
    text-wrap: nowrap;
    font-size: 16px;
    font-weight: 500;
}

/* this stuff could probably go into a button variant */
.nav_header .nav_actions .variant-link:hover,
.nav_header .nav_actions .active .variant-link {
    text-decoration: underline;
    text-decoration-thickness: 2px;
}

a.pill_button {
    display: flex;
    padding: 22px 40px;
    justify-content: center;
    align-items: center;
    gap: 14px;
    border-radius: 94px;
    background: var(--Primary-Dark-Gray, #161618);
    color: var(--Primary-White, #ffffff);
}

.nav_header.dark a.pill_button {
    background: var(--Primary-Dark-Gray);
    color: var(--Primary-White, #ffffff);
}

.nav_header.dark a.pill_button:hover {
    background: var(--Gray-700);
    color: var(--Primary-White);
}

.nav_header.lite a.pill_button {
    background: var(--Primary-White, #ffffff);
    color: var(--Primary-Dark-Gray, #161618);
}



/* .nav_actions a:hover {
    text-decoration: underline;
} */

.menu_button {
    display: none;
}

.mobile_nav {
    position: absolute;
    top: 70px;
    left: 40px;
    /*padding: 16px 32px 16px 16px;*/
    background: white;
    color: var(--Primary-Dark-Gray);
    border-radius: 8px;
    border: 1px solid var(--Primary-Dark-Gray);
    /*box-shadow: var(--Primary-Dark-Gray) 0px 4px 4px 0px;*/
}

.mobile_nav .mobile_nav_links a,
.mobile_nav .mobile_nav_links button {
    color: var(--Primary-Dark-Gray);
    display: block;
    padding: 8px 48px 8px 16px;
    width: 100%;
    text-align: left;
    font-size: 16px;
    font-weight: 500;
    text-decoration: none;

}

.mobile_nav_links li:first-child {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.mobile_nav_links li:last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}

.mobile_nav_links li:hover {
    background-color: var(--Gray-100);
}

.mobile_nav_links li {
    /*padding: 16px;*/
}

.create_dropdown {
    position: absolute;
    background-color: var(--Primary-Yellow);
    border-radius: 0.25rem;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    width: 231px;
    height: 111px;
    overflow: hidden;
    padding: 28px;
    gap: 12px;
}

.create_dropdown li {
    width: 100%;
    display: flex;
    align-items: center;
}

/* .create_dropdown li:hover {
    width: calc(100% + 56px);
    background-color: #f5f5f5;
} */

.create_dropdown a {
    text-decoration: none !important;
    color: black;
    width: 100%;
}

@media (max-width: 1440px) {
    .nav_header {
        padding: 20px 40px;
    }

    .nav_header .nav_links a {
        font-size: 16px;
    }

    .nav_header .nav_actions a {
        font-size: 16px;
    }

    a.pill_button {
        padding: 16px 30px;
        gap: 10px;
    }
}

@media (max-width: 1024px) {
    .nav_header {
        padding: 20px 40px;
    }

    .nav_header.dark .nav_links a,
    .nav_header.dark .nav_links button,
    .nav_header.dark .nav_actions a,
    .nav_header.dark .nav_actions button {
        font-size: 14px;
    }

    .mobile_nav .mobile_nav_links a,
    .mobile_nav .mobile_nav_links button {
        font-size: 14px;
    }

    a.pill_button {
        padding: 16px 30px;
        gap: 10px;
    }
}

@media (max-width: 834px) {
    .nav_links {
        display: none;
    }

    .nav_actions {
        display: none;
    }

    .menu_button {
        display: block;
    }

    .minimal_header .menu_button {
        display: none;
    }

    .logo {
        position: absolute;
        top: 35px;
        left: 50%;
        transform: translateX(-50%);
    }
}

@media (max-width: 430px) {
    .home_get_started {
        width: 100%;
    }

    .nav_header {
        padding: 0 20px 0 20px;
    }

    .minimal_header .logo {
        left: 20px;
        transform: none;
    }

    .wizard_action_button {
        right: 20px !important;
    }
}