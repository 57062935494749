.wizard-text-input {
    color: var(--Primary-Dark-Gray);
    font-family: Lora, serif;
    font-size: 24px;
    font-style: italic;
    font-weight: 400;
    /*line-height: 72px;*/
    border-bottom: 2px solid var(--Secondary-Medium-Gray);
    background: url(../../../../public/right-arrow-lightgray.svg) no-repeat right center;
    background-size: 44px 44px;
    padding-bottom: 10px;
    width: 100%;
}

.wizard-text-input::placeholder {
    opacity: 0.3;
}

.wizard-text-input:focus {
    border-bottom: 2px solid var(--Primary-Dark-Gray);
    outline: none;
    background: url(../../../../public/right-arrow-dark.svg) no-repeat right center;
}

.wizard-textarea {
    border-radius: 12px;
    border: 2px solid var(--Primary-Dark-Gray, #161618);
    background: var(--Gray-100, #F4F5F5);
    width: 100%;
    padding: 32px 40px 32px 40px;
}

.file-upload-component {
    flex-shrink: 0;
    border-radius: 12px;
    color: white;
}

.drag-active {
    border: 1px dashed #FDFD43;
    color: #FDFD43;
}

.term-select {
    -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent;
    background-image: url("../../../../public/images/icon-chevron-down.svg");
    background-repeat: no-repeat;
    background-position-x: 90%;
    background-position-y: 50%;
    margin-right: 2rem;
    padding: 8px 24px 8px 24px;
    color: var(--Primary-Dark-Gray);
    border-radius: 50px;
    border: 1px solid rgba(20, 20, 20, 0.20);
    font-weight: 600;
    font-size: 16px;
    outline: none;
}

.datepicker-button {
    border-radius: 52px;
    border: 1px solid rgba(20, 20, 20, 0.20);
    color: var(--Primary-Dark-Gray);
    padding: 8px 24px 8px 24px;
    font-weight: 600;
    font-size: 16px;
}

.react-datepicker-wrapper {
    border-radius: 52px;
    border: 1px solid rgba(20, 20, 20, 0.20);
    color: var(--Primary-Dark-Gray);
    padding: 8px 24px 8px 24px;
    font-weight: 600;
    font-size: 16px;
}

.react-datepicker__input-container {
    width: 120px;
}

.react-datepicker__input-container input {
    width: 125px;
    background-image: url("../../../../public/images/icon-calendar.svg");
    background-repeat: no-repeat;
    background-position-x: 90%;
    outline: none;
}

.react-datepicker__header {
    text-align: center;
    background-color: var(--Primary-Dark-Gray);
    color: white;
    border-bottom: 0px;
    padding-top: 8px;
    position: relative;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
}

.react-datepicker__current-month {
    color: white;
}

.react-datepicker__header__dropdown {
    color: white;
}

.react-datepicker__day-name {
    color: white;
}

.react-datepicker__day--selected {
    background-color: var(--Primary-Yellow);
    color: var(--Primary-Dark-Gray) !important;
    border-radius: 50px;
}

.react-datepicker {
    background: var(--Primary-Dark-Gray);
    border-radius: 12px;
}

.react-datepicker__month {
    background: var(--Primary-Dark-Gray);
    color: white;
}

.react-datepicker__day {
    color: white;
}

.react-datepicker__day:hover {
    background-color: var(--Primary-Yellow);
    color: var(--Primary-Dark-Gray) !important;
    border-radius: 50px;
}

.react-datepicker__today-button {
    background: var(--Primary-Yellow);
    border-top: 0px;
    cursor: pointer;
    text-align: center;
    font-weight: bold;
    padding: 5px 0;
    clear: left;
}