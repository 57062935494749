.lp_description p {
  margin: 0px 0px 1em;
}

.lp_description h1 {
  font-style: normal;
  font-size: 32px;
  margin: 1em 0;
}

.lp_description h2 {
  font-size: 24px;
  margin: 1em 0;
  font-style: normal;
  font-weight: 500;
}

.lp_description h3 {
  font-size: 20px;
  margin: 1em 0;
  font-style: normal;
}

.lp_description ul {
  list-style: circle;
  padding-left: 1em;
  margin: 1em 0;
}