@media (max-width: 834px) {
    .register_account_type .space-y-2 .radiogroup {
        grid-template-columns: 1fr !important;
    }
    .radio_group_item{
        border-radius: 20px;
        border: 2px solid var(--Gray-300, #E1E2E4);
    }
    .register_account_info{
        grid-template-columns: 1fr !important;
    }
    .register_account_info label{
        font-size: 16px !important;
    }
    .register_account_info button{
        font-size: 16px !important;
    }
    .register_account_info input{
        font-size: 16px !important;
    }
    .register_complete{
        padding: 20px;
        gap: 20px;
        grid-template-columns: 1fr !important;
    }
}
.register_account_info label{
    color: var(--Primary-Dark-Gray);
}
.register_account_info button{
    color: var(--Primary-Dark-Gray);
}
.register_complete_image{
    margin: 48px 0px 48px 0px;
}