.modal_container {
    display: flex;
    /*width: 501px;*/
    /*padding: 29px 42px 48px 42px;*/
    flex-direction: column;
    align-items: flex-start;
    gap: 28px;
}

.modal_image {
    /*height: 280px;*/
    align-self: stretch;
    border-radius: 20px;
    /*background: var(--Primary-Dark-Gray, #161618);*/
}

.modal_pretitle {
    /*color: var(--Primary-Dark-Gray, #161618);*/

    /*!* Sans/S *!*/
    /*font-family: var(--Font-Sans);*/
    /*font-size: 16px;*/
    /*font-style: normal;*/
    /*font-weight: 600;*/
    /*line-height: 120%; !* 19.2px *!*/
}

.modal_title {
    /*color: var(--Primary-Dark-Gray, #161618);*/

    /*!* Serif/XL *!*/
    /*font-family: "Noto Serif";*/
    /*font-size: 32px;*/
    /*font-style: italic;*/
    /*font-weight: 400;*/
    /*line-height: 120%; !* 38.4px *!*/
}

.modal_hint {
    /*color: var(--Primary-Dark-Gray, #161618);*/

    /*!* Body/Regular *!*/
    /*font-family: "Fann Grotesque Trial";*/
    /*font-size: 16px;*/
    /*font-style: normal;*/
    /*font-weight: 400;*/
    /*line-height: 144%; !* 23.04px *!*/
}

.modal_actionbar {}