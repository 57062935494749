.project_file_card{
    display: flex;
    background: var(--Secondary-Medium-Gray);
    border-radius: 12px;
    padding: 20px 40px;
    align-items: center;
    gap: 40px;
}
.project_card_asset_image{
    cursor: pointer;
}
.project_card_asset_name{
    flex: 1;
    cursor: pointer;

}
.project_card_asset_status{
    cursor: pointer;
}
.project_card_asset_action{

}