.wizard-group-nav {
    height: 128px;
    position: fixed;
    z-index: 2;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    color: white;
}

.wizard_panel_holder {
    padding-bottom: 128px;
    max-width: 1440px;
}
@media (max-width: 540px) {
    .wizard_panel_holder {
        padding-bottom: 200px;
    }
    .wizard-panel-holder {
        padding-bottom: 200px;
    }
}

.wizard_page {
    padding-top: 120px;
    padding-bottom: 120px;
}

.wizard_nav_buttonbar {
    position: fixed;
    bottom: 20px;
    right: 10px;
}

.project_terms_panel {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    align-items: flex-start;
}

.project_term_price {
    flex-grow: 1;
    align-items: flex-end;
    text-align: right;
}

@media(max-width: 540px) {
    .wizard_nav_buttonbar {
        position: relative;
        padding: 20px 20px 0 20px !important;
        justify-content: space-between !important;
    }

    .wizard-text-input {
        background-size: 28px 28px !important;
        font-weight: 400 !important;
        font-size: 36px !important;
        /*font-size: var(text-xxxl-serif, 36px) !important;*/
    }

    .project_terms_panel>*:not(:last-child) {
        align-self: flex-start;
    }

    .project_terms_panel>*:last-child {
        flex-basis: 100%;
        text-align: center;
    }

    .react-datepicker-popper {
        left: 20px !important;
    }
}