@import "styles/colors.css";
@import "styles/typography.css";
@import "styles/global.css";
@import "styles/layout.css";
@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root {
  background-color: #141414;
}


@layer base {
  :root {
    --background: 0 0% 0%;
    --foreground: 225, 11%, 14%, 1;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 47.4% 11.2%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 47.4% 11.2%;

    --primary: 61, 100%, 67%;
    --primary-foreground: 0, 0%, 4%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 100% 66%;
    --destructive-foreground: 210 40% 98%;

    --ring: 215 20.2% 65.1%;

    --radius: 0.5rem;
  }

  @font-face {
    font-family: 'Oswald';
    src: url('https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap');
  }

  * {
    @apply border-border;
  }

  body {
    @apply bg-background text-foreground;
    font-feature-settings: "rlig" 1, "calt" 1;
    color: '#202124';
  }
}

a {
  color: var(--Primary-White, #FFF);
  /* text-base/font-medium */
  font-family: var(--Font-Sans);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  /* 24px */
}

a.text:hover {
  text-decoration: underline;
}

a.btn:hover {
  text-decoration: none;
}