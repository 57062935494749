.page_container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}
.page_content {
    width: 100%;
    margin-top: 100px
}
.sub_header{
    padding: 60px 0 60px 0;
    display: flex;
    gap: 23px;
}
.grid_list_container{
    padding: 0;
    /*margin: auto;*/
}
.project_list_container{
    padding: 0 60px 0 60px;
    margin: auto;
}
@media only screen and (max-width: 320px){

}
@media only screen and (min-width: 375px){

}
@media only screen and (min-width: 768px){

}
@media only screen and (min-width: 1024px){
    .page_header {
        max-width: 100%;
        padding-left: 40px;
        padding-right: 40px;
        margin: auto;
    }
}
@media only screen and (min-width: 1200px){
    .page_header {
        max-width: 100%;
        padding-left: 50px;
        padding-right: 50px;
        margin: auto;
    }
    .project_list_container{
        padding: 0 60px 0 60px;
        margin: auto;
    }
    .grid_list_container{
        width: 1200px;
    }
}
@media only screen and (min-width: 1440px){
    .page_header {
        max-width: 100%;
        padding-left: 60px;
        padding-right: 60px;
        margin: auto;
    }
    .grid_list_container{
        width: 1440px;
    }
}
@media only screen and (min-width: 1920px){
    .page_header {
        max-width: 100%;
        padding-left: 80px;
        padding-right: 80px;
        margin: auto;
    }
    .grid_list_container{
        width: 1920px;
    }
}
@media only screen and (min-width: 2500px){
    .page_header {
        max-width: 100%;
        padding-left: 100px;
        padding-right: 100px;
        margin: auto;
    }
    .grid_list_container{
        width: 2500px;
    }
}