.oai-editor.oai-editor-mode-editor.PinturaRootWrapper {
  width: 100%;
}

.oai-editor.oai-editor-mode-editor .pintura-editor {
  --color-background: 0, 0, 0;
  --color-foreground: 255, 255, 255;
}

/* undo redo tools */
/* .oai-editor.oai-editor-mode-editor.oai-editor-mode-pan-and-zoom .PinturaNav.PinturaNavTools {
  display: none;
  padding: 0;
} */

.oai-editor.oai-editor-mode-editor.oai-editor-mode-pan-and-zoom .PinturaUtilMain {
  padding: 0;
}

.oai-editor .PinturaUtilPanel {
  padding-top: 0;
  padding-bottom: 0;
}

.oai-editor .PinturaRoot PinturaButton {
  outline: transparent;
  margin-left: 0.75em;
  font-size: 0.875em;
  border-radius: 12px;
  padding: 0.25em;
  transition: background-color var(--transition-duration-10) ease-out, color var(--transition-duration-10) ease-out;
  -webkit-backdrop-filter: var(--backdrop-filter-dark);
  backdrop-filter: var(--backdrop-filter-dark);
}

.oai-editor.oai-editor-mode-editor .PinturaRangeInput>.PinturaRangeInputReset:after {
  content: "";
  position: absolute;
  left: 50%;
  top: 20%;
  width: 1px;
  height: 60%;
}

.oai-editor.oai-editor-mode-editor .PinturaShapeStyleList .PinturaShapeStyleLabel {
  line-height: 1.75;
  font-size: 0.75em;
  font-weight: 300;
  padding: 0 0.75em;
  pointer-events: none;
  text-align: center;
  color: #fff;
  white-space: nowrap;
  text-transform: none;
}

.pintura-editor,
pintura-editor {
  --font-family: Oswald, sans-serif;
  --border-radius-round: 9999em;
  --border-radius: 12px;
}

/* ORIGINAL CSS (FORMATTED) BELOW FROM @pqina/pintura/pintura.css */
/* REMOVE AFTER OVERRIDES */

/*!
 * Pintura v8.92.4 - Test version
 * (c) 2018-2025 PQINA Inc. - All Rights Reserved
 * License: https://pqina.nl/pintura/license/
 * 
 * This version of Pintura is for testing purposes only.
 * Visit https://pqina.nl/pintura/ to obtain a commercial license.
 */
/* eslint-disable */

/*
.PinturaUtilPanel[data-util="redact"] .PinturaUtilFooter {
  padding: 0;
}

.PinturaUtilPanel[data-util="redact"] .PinturaUtilFooter > div {
  height: 1px;
}

.PinturaUtilPanel[data-util="retouch"] .PinturaControlListScroller + .PinturaControlListScroller {
  margin-top: 1em;
}



.pintura-editor,
.pintura-editor-panel,
pintura-editor {
  --color-foreground-100: rgba(var(--color-foreground), 1);
  --color-foreground-95: rgba(var(--color-foreground), 0.95);
  --color-foreground-90: rgba(var(--color-foreground), 0.9);
  --color-foreground-80: rgba(var(--color-foreground), 0.8);
  --color-foreground-70: rgba(var(--color-foreground), 0.7);
  --color-foreground-60: rgba(var(--color-foreground), 0.6);
  --color-foreground-50: rgba(var(--color-foreground), 0.5);
  --color-foreground-40: rgba(var(--color-foreground), 0.4);
  --color-foreground-30: rgba(var(--color-foreground), 0.3);
  --color-foreground-20: rgba(var(--color-foreground), 0.25);
  --color-foreground-15: rgba(var(--color-foreground), 0.2);
  --color-foreground-10: rgba(var(--color-foreground), 0.15);
  --color-foreground-5: rgba(var(--color-foreground), 0.075);
  --color-foreground-3: rgba(var(--color-foreground), 0.05);
  --color-foreground-1: rgba(var(--color-foreground), 0.02);
  --color-foreground-0: rgba(var(--color-foreground), 0);
  --color-background-100: rgba(var(--color-background), 1);
  --color-background-95: rgba(var(--color-background), 0.95);
  --color-background-90: rgba(var(--color-background), 0.9);
  --color-background-80: rgba(var(--color-background), 0.8);
  --color-background-70: rgba(var(--color-background), 0.7);
  --color-background-60: rgba(var(--color-background), 0.6);
  --color-background-50: rgba(var(--color-background), 0.5);
  --color-background-40: rgba(var(--color-background), 0.4);
  --color-background-30: rgba(var(--color-background), 0.3);
  --color-background-20: rgba(var(--color-background), 0.2);
  --color-background-15: rgba(var(--color-background), 0.15);
  --color-background-10: rgba(var(--color-background), 0.1);
  --color-background-5: rgba(var(--color-background), 0.05);
  --color-background-3: rgba(var(--color-background), 0.03);
  --color-background-1: rgba(var(--color-background), 0.01);
  --color-background-0: rgba(var(--color-background), 0);
}

.pintura-editor,
.PinturaRootWrapper,
pintura-editor {
  display: block;
}

.PinturaRootWrapper {
  height: 100%;
}

.PinturaScrollableContent {
  overflow: hidden;
  max-height: var(--panel-max-height, 100vh);
  overflow-y: auto;
  scrollbar-color: var(--color-foreground-30) transparent;
  scrollbar-width: thin;
}

.PinturaScrollableContent::-webkit-scrollbar {
  width: 1em;
  cursor: pointer;
}

.PinturaScrollableContent::-webkit-scrollbar-track {
  background: none;
}

.PinturaScrollableContent::-webkit-scrollbar-thumb {
  cursor: pointer;
  background-clip: padding-box;
  background-color: var(--color-foreground-30);
  border-radius: 9999em;
  border: 0.3125em solid transparent;
}

.PinturaRoot[data-env~="is-animated"] {
  --transition-duration-multiplier: 1;
}

.PinturaRoot[data-env~="is-transparent"] {
  background: none;
}

.PinturaRoot {
  --nav-group-margin: 0.5em;
  --editor-calculated-inset-top: calc(var(--safe-area-inset-top) + var(--editor-inset-top));
  --editor-calculated-inset-bottom: calc(var(--safe-area-inset-bottom) + var(--editor-inset-bottom));
  --transition-duration-10: calc(var(--transition-duration-multiplier, 0) * 0.1s);
  --transition-duration-25: calc(var(--transition-duration-multiplier, 0) * 0.25s);
  --transition-duration-50: calc(var(--transition-duration-multiplier, 0) * 0.5s);
  box-sizing: border-box;
  width: var(--editor-width, 100%);
  height: var(--editor-height, 100%);
  padding-top: var(--editor-calculated-inset-top);
  padding-bottom: var(--editor-calculated-inset-bottom);
  max-width: var(--editor-max-width, var(--editor-max-width-default, none));
  max-height: var(--editor-max-height, var(--editor-max-height-default, none));
  position: relative;
  overflow: hidden;
  contain: strict;
  display: grid;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  line-height: normal;
  font-size: var(--font-size);
  font-family: var(--font-family);
  font-weight: 450;
  touch-action: manipulation;
  text-align: left;
  text-transform: none;
  text-rendering: optimizeLegibility;
  direction: ltr;
  color: var(--color-foreground-90);
  background-color: var(--color-background-100);
  outline-color: rgba(var(--color-preview-outline), 1);
  transition: background-color 1ms, outline-color 1ms, color var(--transition-duration-10) ease-in-out, dir 1ms;
}

.PinturaRoot[dir="rtl"] button {
  direction: rtl;
}

.PinturaRoot .PinturaUtilMain {
  cursor: var(--cursor);
}

.PinturaRoot * {
  box-sizing: content-box;
  word-wrap: normal;
}

.PinturaRoot div:empty {
  display: block;
}

.PinturaRoot button,
.PinturaRoot fieldset,
.PinturaRoot input,
.PinturaRoot legend {
  padding: 0;
  margin: 0;
  border: none;
  background: transparent;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
  width: auto;
  text-decoration: none;
}

.PinturaRoot button[disabled] {
  pointer-events: none;
}

.PinturaRoot canvas {
  display: block;
}

.PinturaRoot svg {
  display: inline-block;
  width: auto;
  height: auto;
  max-width: none;
}

.PinturaRoot p {
  margin: 0;
}

.PinturaRoot li,
.PinturaRoot ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.PinturaRoot a {
  color: inherit;
  text-decoration: none;
}

.PinturaRoot .implicit {
  border: 0;
  clip: rect(0 0 0 0);
  -webkit-clip-path: polygon(0 0, 0 0, 0 0);
  clip-path: polygon(0 0, 0 0, 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
}

.PinturaRoot .PinturaFixedWidthCharacters {
  font-variant: tabular-nums;
}

.PinturaRoot .PinturaStage {
  display: flex;
  align-self: stretch;
  flex: 1;
  pointer-events: none;
}

.PinturaRoot .PinturaStage[tabindex="-1"] {
  outline: none;
}

.PinturaRoot .PinturaRootPortal,
.PinturaRoot > .PinturaCanvas {
  position: absolute;
  left: 0;
  top: 0;
}

.PinturaRoot > .PinturaCanvas {
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 0;
}

.PinturaRoot > .PinturaCanvas canvas {
  position: absolute;
  width: 100%;
  height: 100%;
}

.PinturaRoot > .PinturaCanvas:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background-repeat: no-repeat;
}

.PinturaRoot > .PinturaStatus {
  position: absolute;
  z-index: 99999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background-color: var(--color-background-90);
}

.PinturaRoot > .PinturaStatus > p {
  position: absolute;
  top: 50%;
  left: 50%;
  display: inline-flex;
  white-space: nowrap;
  align-items: center;
  transition: opacity var(--transition-duration-50) ease-out;
}

.PinturaRoot .PinturaStatusMessage {
  position: absolute;
  font-size: 0.875em;
}

.PinturaRoot .PinturaStatusAside {
  position: absolute;
  left: 0;
}

.PinturaRoot .PinturaStatusAside svg {
  width: 1.25em;
  height: 1.25em;
}

.PinturaRoot .PinturaStatusAside .PinturaProgressIndicator {
  margin-left: 0.5em;
}

.PinturaRoot .PinturaStatusAside .PinturaButton {
  outline: transparent;
  margin-left: 0.75em;
  font-size: 0.875em;
  border-radius: var(--border-radius-round);
  background-color: var(--color-foreground-5);
  padding: 0.25em;
  transition: background-color var(--transition-duration-10) ease-out, color var(--transition-duration-10) ease-out;
  -webkit-backdrop-filter: var(--backdrop-filter-dark);
  backdrop-filter: var(--backdrop-filter-dark);
}

.PinturaRoot .PinturaStatusAside .PinturaButton:not([disabled])[data-focus-visible] {
  background-color: var(--color-focus-50);
  box-shadow: inset 0 0 0 1px var(--color-focus-100);
}

.PinturaRoot .PinturaStatusAside .PinturaButton:not([disabled]):hover {
  background-color: var(--color-foreground-10);
}

.PinturaRoot .PinturaStatusIcon svg {
  font-size: 0.875em;
  margin-top: 0.25em;
  margin-left: 0.5em;
}

.PinturaRoot > .PinturaNav {
  position: relative;
  z-index: 3;
}

.PinturaRoot > .PinturaNav:empty {
  display: none !important;
}

.PinturaRoot > .PinturaNav .PinturaButton {
  outline: transparent;
  transition: background-color var(--transition-duration-10) ease-out, color var(--transition-duration-10) ease-out;
}

.PinturaRoot > .PinturaNav .PinturaButton svg {
  width: 1em;
  transition: opacity var(--transition-duration-10) ease-out;
}

.PinturaRoot > .PinturaNav .PinturaButton > span {
  justify-content: center;
}

.PinturaRoot > .PinturaNav .PinturaButtonIconOnly .PinturaButtonInner,
.PinturaRoot > .PinturaNav .PinturaDropdownIconOnly .PinturaButtonInner {
  width: 1.75em;
}

.PinturaRoot > .PinturaNav .PinturaButton .PinturaButtonInner {
  height: 1.75em;
}

.PinturaRoot > .PinturaNav .PinturaButton:not(.PinturaButtonIconOnly):not(.PinturaDropdownIconOnly) {
  padding-left: 0.75em;
  padding-right: 0.75em;
}

.PinturaRoot
  > .PinturaNav
  .PinturaButton:not(.PinturaButtonIconOnly):not(.PinturaDropdownIconOnly)
  .PinturaButtonLabel {
  font-size: 0.75em;
  line-height: 2;
}

.PinturaRoot
  > .PinturaNav
  .PinturaButton:not(.PinturaButtonIconOnly):not(.PinturaDropdownIconOnly):not(.PinturaDropdownButton) {
  box-shadow: inset 0 0 0 1px var(--color-foreground-5);
}

.PinturaRoot > .PinturaNav .PinturaButton:only-of-type {
  border-radius: var(--border-radius-round);
}

.PinturaRoot > .PinturaNav .PinturaButton:only-of-type + * {
  margin-left: 0.5em;
}

.PinturaRoot > .PinturaNav .PinturaButton[disabled] .PinturaButtonLabel,
.PinturaRoot > .PinturaNav .PinturaButton[disabled] svg {
  opacity: 0.25;
}

.PinturaRoot > .PinturaNav .PinturaButton:not(.PinturaButtonExport):not([disabled])[data-focus-visible] {
  background-color: var(--color-focus-50);
  box-shadow: inset 0 0 0 1px var(--color-focus-100);
}

.PinturaRoot > .PinturaNav .PinturaButton:not(.PinturaButtonExport):not([disabled]):hover {
  background-color: var(--color-foreground-10);
}

.PinturaRoot .PinturaButtonExport {
  color: var(--color-primary-text);
  background-color: var(--color-primary);
}

.PinturaRoot .PinturaButtonExport:hover {
  background-color: var(--color-primary-dark);
}

.PinturaRoot .PinturaButtonExport[data-focus-visible] {
  color: var(--color-primary-text);
  background-color: var(--color-primary);
  box-shadow: inset 0 0 0 1px var(--color-foreground-5), 0 0 0 3px var(--color-focus-50);
  background-color: var(--color-primary-dark);
}

.PinturaRoot .PinturaButtonExport:not(.PinturaButtonIconOnly) {
  flex: 1;
  padding: 0 0.75em;
}

.PinturaRoot > .PinturaNavMain {
  display: flex;
  overflow: hidden;
}

.PinturaRoot > .PinturaNavMain [aria-selected="true"] {
  -webkit-backdrop-filter: var(--backdrop-filter-dark);
  backdrop-filter: var(--backdrop-filter-dark);
  background-color: var(--color-foreground-10);
}

.PinturaRoot > .PinturaNavMain button svg {
  align-self: center;
  width: 1.5em;
}

.PinturaRoot > .PinturaNavMain button span,
.PinturaRoot > .PinturaNavMain button svg {
  pointer-events: none;
}

.PinturaRoot > .PinturaNavMain button svg + span {
  margin-top: 0.75em;
}

.PinturaRoot > .PinturaNavMain button span {
  font-size: 0.875em;
  display: block;
  width: calc(100% - 1em);
  overflow: hidden;
  text-overflow: ellipsis;
  width: inherit;
  max-width: calc(100% - 1em);
}

.PinturaRoot > .PinturaNavMain button {
  box-shadow: inset 0 0 0 1px var(--color-foreground-5);
  transition: background-color var(--transition-duration-10) ease-out, color var(--transition-duration-10) ease-out,
    box-shadow var(--transition-duration-10) ease-out;
}

.PinturaRoot > .PinturaNavMain button:hover {
  box-shadow: inset 0 0 0 1px var(--color-foreground-10);
}

.PinturaRoot > .PinturaNavMain button[data-focus-visible] {
  background-color: var(--color-focus-50);
  box-shadow: inset 0 0 0 1px var(--color-focus-100);
}

.PinturaRoot > .PinturaNavTools {
  max-width: 100%;
  box-sizing: border-box;
  pointer-events: none;
}

.PinturaRoot > .PinturaNavTools:empty {
  display: none;
}

.PinturaRoot > .PinturaNavTools,
.PinturaRoot > .PinturaNavTools .PinturaNavGroup,
.PinturaRoot > .PinturaNavTools .PinturaNavSet {
  display: flex;
  align-items: center;
  justify-content: center;
}

.PinturaRoot > .PinturaNavTools .PinturaButton,
.PinturaRoot > .PinturaNavTools .PinturaNavSet {
  pointer-events: all;
}

.PinturaRoot > .PinturaNavTools .PinturaButton[data-focus-visible] {
  background-color: var(--color-focus-50);
  box-shadow: inset 0 0 0 1px var(--color-focus-100);
}

.PinturaRoot .PinturaNavGroup {
  margin: 0 var(--nav-group-margin);
}

.PinturaRoot .PinturaNavGroup > * {
  border-radius: var(--border-radius-round);
}

.PinturaRoot .PinturaNavGroup > * + * {
  margin-left: 0.5em;
}

.PinturaRoot .PinturaNavGroup:first-of-type:not(.PinturaNavGroupFloat) {
  margin-right: auto;
  margin-left: 0;
}

.PinturaRoot .PinturaNavGroup:last-of-type:not(.PinturaNavGroupFloat) {
  margin-left: auto;
  margin-right: 0;
}

.PinturaRoot .PinturaNavSet {
  box-shadow: inset 0 0 0 1px var(--color-foreground-5);
}

.PinturaRoot .PinturaNavSet > :not(:only-child) {
  border: 1px solid var(--color-foreground-3);
  border-top: 0;
  border-bottom: 0;
}

.PinturaRoot .PinturaNavSet > :first-child:not(:only-child) {
  border-left: 0;
}

.PinturaRoot .PinturaNavSet > :last-child:not(:only-child) {
  border-right: 0;
}

.PinturaRoot .PinturaNavSet > * + * {
  margin-left: -1px;
}

.PinturaRoot .PinturaNavSet > .PinturaButton:hover,
.PinturaRoot .PinturaNavSet > .PinturaButton[data-focus-visible] {
  position: relative;
  z-index: 1;
}

.PinturaRoot .PinturaNavSet > :only-child {
  border-radius: var(--border-radius-round);
}

.PinturaRoot .PinturaNavSet > :first-child:not(:only-child) .PinturaButtonInner {
  padding: 0 0.125em 0 0.25em;
}

.PinturaRoot .PinturaNavSet > :first-child:not(:only-child) {
  border-top-left-radius: var(--border-radius-round);
  border-bottom-left-radius: var(--border-radius-round);
}

.PinturaRoot .PinturaNavSet > :last-child:not(:only-child) .PinturaButtonInner {
  padding: 0 0.25em 0 0.125em;
}

.PinturaRoot .PinturaNavSet > :last-child:not(:only-child) {
  border-top-right-radius: var(--border-radius-round);
  border-bottom-right-radius: var(--border-radius-round);
}

.PinturaRoot[data-env~="overlay"] .PinturaNavSet {
  background-color: var(--color-background-50);
  -webkit-backdrop-filter: var(--backdrop-filter-bright);
  backdrop-filter: var(--backdrop-filter-bright);
  box-shadow: inset 0 0 0 1px var(--color-background-15), 0 0.0625em 0.125em rgba(0, 0, 0, 0.25),
    0 0.125em 0.35em rgba(0, 0, 0, 0.35);
}

.PinturaRoot[data-env~="overlay"] .PinturaNavSet .PinturaButton:not(.PinturaButtonExport):not([disabled]):hover,
.PinturaRoot[data-env~="overlay"] .PinturaNavSet > * {
  border-color: var(--color-background-15);
}

.PinturaRoot[data-env~="overlay"] .PinturaNavGroup > .PinturaButton {
  box-shadow: 0 0.0625em 0.125em rgba(0, 0, 0, 0.25), 0 0.125em 0.35em rgba(0, 0, 0, 0.35);
}

.PinturaRoot[data-env~="overlay"] .PinturaNavGroup .PinturaButtonExport {
  box-shadow: inset 0 0 0.125em rgba(0, 0, 0, 0.25), 0 0.0625em 0.125em rgba(0, 0, 0, 0.25),
    0 0.125em 0.35em rgba(0, 0, 0, 0.35);
}

.PinturaRoot .PinturaNavGroupFloat {
  pointer-events: none;
}

.PinturaRoot .PinturaNavGroupFloat > * {
  pointer-events: all;
}

.PinturaRoot .PinturaNavGroupFloat:not(:only-child) {
  position: absolute;
  left: 0;
  right: 0;
}

.PinturaRoot .PinturaUtilFooter .PinturaScrollable > div {
  padding-left: 1em;
  padding-right: 1em;
}

.PinturaRoot > .PinturaMain {
  max-width: 100vw;
}

.PinturaRoot:not([data-env~="has-toolbar"]) .PinturaUtilHeader {
  padding-top: 0;
  padding-bottom: 1em;
  margin-bottom: -1em;
}

.PinturaRoot[data-env~="landscape"] {
  grid-template-rows: -webkit-min-content auto;
  grid-template-rows: min-content auto;
  grid-template-columns: auto;
}

.PinturaRoot[data-env~="landscape"] > .PinturaNavTools {
  grid-row: 1;
  grid-column: 1;
  padding: 1em 1em 0;
  z-index: 3;
}

.PinturaRoot[data-env~="landscape"] > .PinturaNavMain {
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  left: 1em;
  top: calc(1em + var(--editor-calculated-inset-top));
  bottom: calc(1em + var(--editor-calculated-inset-bottom));
}

.PinturaRoot[data-env~="landscape"] > .PinturaNavMain .PinturaTabList {
  flex-direction: column;
}

.PinturaRoot[data-env~="landscape"] > .PinturaNavMain .PinturaTabList button {
  display: flex;
}

.PinturaRoot[data-env~="landscape"] > .PinturaNavMain .PinturaTabList button + button {
  margin: 0.5em 0 0;
}

.PinturaRoot[data-env~="landscape"] > .PinturaNavMain button {
  flex: 1;
  min-width: 4em;
  min-height: 4em;
  border-radius: var(--border-radius);
  justify-content: center;
}

.PinturaRoot[data-env~="landscape"] > .PinturaNavMain button span {
  font-size: 0.6875em;
  margin-top: 0.5em;
}

.PinturaRoot[data-env~="landscape"] > .PinturaNavMain button svg {
  width: 1em;
  margin-top: 0.25em;
}

.PinturaRoot[data-env~="landscape"][data-env~="has-toolbar"] > .PinturaNavMain {
  top: calc(4em + var(--editor-calculated-inset-top));
  bottom: calc(4em + var(--editor-calculated-inset-bottom));
}

.PinturaRoot[data-env~="landscape"][data-env~="has-limited-space"] > .PinturaNavMain {
  align-items: flex-start;
  bottom: calc(1em + var(--editor-calculated-inset-bottom));
}

.PinturaRoot[data-env~="landscape"] > .PinturaMain {
  grid-row: 2;
  grid-column: 1;
}

.PinturaRoot[data-env~="landscape"][data-env~="has-toolbar-preference-bottom"] {
  grid-template-rows: auto -webkit-min-content;
  grid-template-rows: auto min-content;
}

.PinturaRoot[data-env~="landscape"][data-env~="has-toolbar-preference-bottom"] > .PinturaNavTools {
  grid-row: 2;
  padding-top: 0;
  padding-bottom: 1em;
}

.PinturaRoot[data-env~="landscape"][data-env~="has-toolbar-preference-bottom"] > .PinturaMain {
  grid-row: 1;
}

.PinturaRoot[data-env~="landscape"][data-env~="has-navigation"] {
  grid-template-columns: 6em auto;
}

.PinturaRoot[data-env~="landscape"][data-env~="has-navigation"]:not([data-env~="narrow"]) .PinturaNavGroupFloat {
  margin: 0 0 0 7em;
}

.PinturaRoot[data-env~="landscape"][data-env~="has-navigation"] > .PinturaNavTools {
  grid-column: 1 / span 2;
}

.PinturaRoot[data-env~="landscape"][data-env~="has-navigation"] > .PinturaMain {
  grid-column: 2;
}

.PinturaRoot[data-env~="landscape"][data-env~="has-navigation"]:not([data-env~="has-navigation-preference-right"])
  .PinturaUtilMain {
  padding-left: 0;
}

.PinturaRoot[data-env~="landscape"][data-env~="has-navigation"]:not([data-env~="has-navigation-preference-right"])
  .PinturaUtilFooter {
  padding-right: 1em;
}

.PinturaRoot[data-env~="landscape"][data-env~="has-navigation"][data-env~="has-navigation-preference-right"] {
  grid-template-columns: auto 6em;
}

.PinturaRoot[data-env~="landscape"][data-env~="has-navigation"][data-env~="has-navigation-preference-right"]:not(
    [data-env~="narrow"]
  )
  .PinturaNavGroupFloat {
  margin: 0 7em 0 0;
}

.PinturaRoot[data-env~="landscape"][data-env~="has-navigation"][data-env~="has-navigation-preference-right"]
  > .PinturaNavMain {
  right: 1em;
  left: auto;
}

.PinturaRoot[data-env~="landscape"][data-env~="has-navigation"][data-env~="has-navigation-preference-right"]
  > .PinturaMain {
  grid-column: 1;
}

.PinturaRoot[data-env~="landscape"][data-env~="has-navigation"][data-env~="has-navigation-preference-right"]
  .PinturaUtilMain {
  padding-right: 0;
}

.PinturaRoot[data-env~="landscape"][data-env~="has-navigation"][data-env~="has-navigation-preference-right"]
  .PinturaUtilFooter {
  padding-left: 1em;
}

.PinturaRoot[data-env~="portrait"] {
  grid-template-rows: -webkit-min-content auto -webkit-min-content;
  grid-template-rows: min-content auto min-content;
  grid-template-columns: auto;
}

.PinturaRoot[data-env~="portrait"] > * {
  grid-column: 1;
}

.PinturaRoot[data-env~="portrait"] > .PinturaNavTools {
  grid-row: 1;
  padding: 0.75em 0.75em 0;
}

.PinturaRoot[data-env~="portrait"] > .PinturaNavMain {
  grid-row: 3;
  margin-bottom: 0.75em;
  justify-content: center;
}

.PinturaRoot[data-env~="portrait"] > .PinturaNavMain .PinturaTabList {
  padding: 0 0.875em;
}

.PinturaRoot[data-env~="portrait"] > .PinturaNavMain button + button {
  margin-left: 0.5em;
}

.PinturaRoot[data-env~="portrait"] > .PinturaNavMain[data-state~="overflows"] {
  overflow: visible;
}

.PinturaRoot[data-env~="portrait"] > .PinturaNavMain button {
  flex: 1;
  min-width: 4em;
  min-height: 4em;
  border-radius: var(--border-radius);
  justify-content: center;
}

.PinturaRoot[data-env~="portrait"] > .PinturaNavMain button span {
  font-size: 0.6875em;
  margin-top: 0.5em;
}

.PinturaRoot[data-env~="portrait"] > .PinturaNavMain button svg {
  width: 1em;
  margin-top: 0.25em;
}

.PinturaRoot[data-env~="portrait"] > .PinturaMain {
  grid-row: 2;
  grid-column: 1;
}

.PinturaRoot[data-env~="portrait"][data-env~="has-toolbar-preference-bottom"] {
  grid-template-rows: auto -webkit-min-content -webkit-min-content;
  grid-template-rows: auto min-content min-content;
}

.PinturaRoot[data-env~="portrait"][data-env~="has-toolbar-preference-bottom"] > .PinturaNavTools {
  grid-row: 3;
  padding: 0 0.75em 0.75em;
}

.PinturaRoot[data-env~="portrait"][data-env~="has-toolbar-preference-bottom"] > .PinturaNavMain {
  grid-row: 2;
}

.PinturaRoot[data-env~="portrait"][data-env~="has-toolbar-preference-bottom"] > .PinturaMain {
  grid-row: 1;
}

.PinturaRoot[data-env~="portrait"]:not([data-env~="narrow"])[data-env~="has-navigation-preference-top"] {
  grid-template-rows: -webkit-min-content -webkit-min-content auto;
  grid-template-rows: min-content min-content auto;
}

.PinturaRoot[data-env~="portrait"]:not([data-env~="narrow"])[data-env~="has-navigation-preference-top"] > .PinturaMain {
  grid-row: 3;
}

.PinturaRoot[data-env~="portrait"]:not([data-env~="narrow"])[data-env~="has-navigation-preference-top"]
  > .PinturaNavMain {
  grid-row: 2;
  margin-top: 0.75em;
  margin-bottom: 0;
}

.PinturaRoot[data-env~="portrait"]:not(
    [data-env~="narrow"]
  )[data-env~="has-navigation-preference-top"][data-env~="has-toolbar-preference-bottom"] {
  grid-template-rows: -webkit-min-content auto -webkit-min-content;
  grid-template-rows: min-content auto min-content;
}

.PinturaRoot[data-env~="portrait"]:not(
    [data-env~="narrow"]
  )[data-env~="has-navigation-preference-top"][data-env~="has-toolbar-preference-bottom"]
  > .PinturaNavMain {
  grid-row: 1;
}

.PinturaRoot[data-env~="portrait"]:not(
    [data-env~="narrow"]
  )[data-env~="has-navigation-preference-top"][data-env~="has-toolbar-preference-bottom"]
  > .PinturaMain {
  grid-row: 2;
}

.PinturaRoot[data-env~="portrait"]:not(
    [data-env~="narrow"]
  )[data-env~="has-navigation-preference-top"][data-env~="has-toolbar-preference-bottom"]
  > .PinturaNavTools {
  grid-row: 3;
}

.PinturaRoot[data-env~="landscape"][data-env~="is-compact"] > .PinturaNavMain button {
  flex: 1;
  flex-direction: row;
  width: auto;
  height: auto;
  min-height: auto;
  border-radius: var(--border-radius);
  justify-content: flex-start;
  align-items: center;
  padding: 0.625em 0;
  width: 6em;
}

.PinturaRoot[data-env~="landscape"][data-env~="is-compact"] > .PinturaNavMain button span {
  font-size: 0.6875em;
  margin: 0 0.625em 0 0;
  line-height: 1.2;
  text-align: left;
}

.PinturaRoot[data-env~="landscape"][data-env~="is-compact"] > .PinturaNavMain button svg {
  margin: 0 0.5em 0 0.625em;
  min-width: 1em;
}

.PinturaRoot[data-env~="landscape"][data-env~="is-compact"][data-env~="has-navigation"] {
  grid-template-columns: 8em auto;
}

.PinturaRoot[data-env~="landscape"][data-env~="is-compact"][data-env~="has-navigation"][data-env~="has-navigation-preference-right"] {
  grid-template-columns: auto 8em;
}

.PinturaRoot[data-env~="landscape"][data-env~="is-compact"][dir="rtl"] > .PinturaNavMain button {
  padding-left: 0.625em;
}

.PinturaRoot[data-env~="landscape"][data-env~="is-compact"][dir="rtl"] > .PinturaNavMain svg {
  margin-left: 0;
}

.PinturaRoot[data-env~="narrow"] .PinturaNavGroupFloat {
  position: static;
  margin: 0;
}

.PinturaRoot[data-env~="portrait"][data-env~="is-compact"] > .PinturaNavMain button {
  padding: 0;
  font-size: 0.625em;
  min-width: 4em;
  min-height: 4em;
  justify-content: center;
  border-radius: 9999em;
}

.PinturaRoot[data-env~="portrait"][data-env~="is-compact"] > .PinturaNavMain button span {
  visibility: hidden;
  font-size: 0;
  margin: 0;
}

.PinturaRoot[data-env~="portrait"][data-env~="is-compact"] > .PinturaNavMain button svg {
  margin-top: 0;
  width: 1.6em;
}

.PinturaRoot[data-env~="portrait"][data-env~="is-compact"] > .PinturaNavMain button svg [stroke-width] {
  stroke-width: 0.15em;
}

.PinturaRoot[data-env~="portrait"][data-env~="narrow"] {
  --nav-group-margin: 0.25em;
  grid-template-rows: -webkit-min-content auto -webkit-min-content;
  grid-template-rows: min-content auto min-content;
  grid-template-columns: auto;
}

.PinturaRoot[data-env~="portrait"][data-env~="narrow"] > * {
  grid-column: 1;
}

.PinturaRoot[data-env~="portrait"][data-env~="narrow"]
  .PinturaUtilFooter
  .PinturaControlList
  .PinturaControlListOption
  span,
.PinturaRoot[data-env~="portrait"][data-env~="narrow"] .PinturaUtilFooter .PinturaControlList [role="tab"] span {
  font-size: 0.6875em;
}

.PinturaRoot[data-env~="portrait"][data-env~="narrow"] .PinturaToolbar .PinturaToolbarInner > * {
  margin: 0 0.25em;
}

.PinturaRoot[data-env~="portrait"][data-env~="narrow"][data-env~="has-toolbar-preference-bottom"] {
  grid-template-rows: auto -webkit-min-content -webkit-min-content;
  grid-template-rows: auto min-content min-content;
}

.PinturaRoot[data-env~="overlay"] {
  grid-template-rows: auto !important;
  grid-template-columns: auto !important;
}

.PinturaRoot[data-env~="overlay"] .PinturaNav {
  z-index: 3;
}

.PinturaRoot[data-env~="overlay"] .PinturaMain {
  grid-row: 1;
  grid-column: 1;
}

.PinturaRoot[data-env~="overlay"] .PinturaUtilMain {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  padding: 0;
  z-index: -1;
}

.PinturaRoot[data-env~="overlay"] .PinturaUtilFooter,
.PinturaRoot[data-env~="overlay"] .PinturaUtilHeader {
  position: absolute;
  left: 0;
  right: 0;
}

.PinturaRoot[data-env~="overlay"] .PinturaUtilHeader {
  top: 0;
}

.PinturaRoot[data-env~="overlay"] .PinturaUtilFooter {
  bottom: 0;
}

.PinturaRoot[data-env~="overlay"] .PinturaNavMain {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  background: none;
}

.PinturaRoot[data-env~="overlay"] .PinturaNavTools {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
}

.PinturaRoot[data-env~="overlay"][data-env~="has-controlgroups-preference-top"] .PinturaUtilFooter {
  top: 0;
  bottom: auto;
}

.PinturaRoot[data-env~="overlay"][data-env~="has-toolbar-preference-bottom"] .PinturaNavTools {
  top: auto;
  bottom: 0;
}

.PinturaRoot[data-env~="has-swipe-navigation"] .PinturaUtilMain {
  padding-left: 2em;
  padding-right: 2em;
}

.PinturaRoot[data-env~="has-swipe-navigation"][data-env~="landscape"] .PinturaUtilMain[data-env~="has-navigation"] {
  padding-left: 0;
  padding-right: 2em;
}

.PinturaRoot[data-env~="has-swipe-navigation"][data-env~="landscape"]
  .PinturaUtilMain[data-env~="has-navigation"][data-env~="has-navigation-preference-right"] {
  padding-left: 2em;
  padding-right: 0;
}

.PinturaRoot[data-env*="is-disabled"],
.PinturaRoot[data-env*="is-disabled"] * {
  pointer-events: none !important;
}

.PinturaRoot[data-env*="is-disabled"] {
  --color-primary: #b4b4b4;
  --color-primary-dark: #a0a0a0;
  --color-primary-text: #000;
  --color-secondary: #969696;
  --color-secondary-dark: #646464;
}

.PinturaRoot[data-env*="is-disabled"] .PinturaCanvas,
.PinturaRoot[data-env*="is-disabled"] .PinturaColorPickerButton {
  filter: grayscale(100%);
}

.PinturaRoot .PinturaEditorOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  background: var(--color-background-50);
  pointer-events: none;
}

@media not all and (-webkit-min-device-pixel-ratio: 0), not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    .PinturaNav {
      will-change: transform;
    }
  }
}

.PinturaUtilPanel[data-util="frame"] .PinturaShapeStyleEditor {
  min-height: 3.375em;
}

.PinturaUtilPanel[data-util="frame"] .PinturaRadioGroupOption {
  margin: 1em 1em 0 0;
  padding: 0.125em;
  border: 1px dashed var(--color-foreground-15);
  color: var(--color-foreground-70);
}

.PinturaUtilPanel[data-util="frame"] .PinturaRadioGroupOption span {
  color: var(--color-foreground-90);
}

.PinturaUtilPanel[data-util="frame"] .PinturaRadioGroupOption label {
  font-size: 0.75em;
}

.PinturaUtilPanel[data-util="frame"] .PinturaRadioGroupOption img,
.PinturaUtilPanel[data-util="frame"] .PinturaRadioGroupOption svg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}

.PinturaUtilPanel[data-util="frame"] [data-selected="true"] {
  border-color: var(--color-primary);
  color: var(--color-primary);
}

.PinturaUtilPanel[data-util="frame"] [data-selected="true"] span {
  color: var(--color-foreground-90);
}

.PinturaUtilPanel[data-util="frame"] [data-layout="row"] > .PinturaRadioGroupOptions > li {
  margin: 0 0.5em;
}

.PinturaUtilPanel[data-util="frame"] [data-layout="row"] > .PinturaRadioGroupOptions > li:first-child {
  margin-left: 0;
}

.PinturaUtilPanel[data-util="frame"] [data-layout="row"] > .PinturaRadioGroupOptions > li:last-child {
  margin-right: 0;
}

.PinturaUtilPanel[data-util="frame"] .PinturaRadioGroupOptionGroupLabel {
  width: 1px;
  height: 1px;
  position: absolute;
  font-size: 0;
}

.PinturaUtilPanel[data-util="frame"] .PinturaRadioGroupOptionGroup {
  display: flex;
  flex-direction: column-reverse;
}

.PinturaUtilPanel[data-util="frame"] .PinturaRadioGroupOption {
  cursor: var(--button-cursor);
  margin-bottom: 1em;
}

.PinturaUtilPanel[data-util="frame"] .PinturaRadioGroupOption span {
  display: block;
  text-align: center;
  padding: 0 1.5em;
  font-size: 0.625em;
  font-weight: 400;
}

.PinturaUtilPanel[data-util="frame"] [data-focus-visible] + label span {
  background-color: var(--color-focus-50);
  box-shadow: inset 0 0 0 1px var(--color-focus-100);
}

.PinturaUtilPanel[data-util="frame"] .PinturaRadioGroupOption {
  position: relative;
  width: 3em;
  height: 4em;
}

.PinturaUtilPanel[data-util="frame"] .PinturaRadioGroupOption div {
  position: absolute;
  outline-color: currentColor;
  border-color: currentColor;
  cursor: var(--button-cursor);
}

.PinturaUtilPanel[data-util="frame"] .PinturaRadioGroupOption div[slot] {
  overflow: hidden;
  left: -1px;
  top: -1px;
  bottom: -1px;
  right: -1px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.PinturaRoot[data-env~="is-compact"] .PinturaUtilPanel[data-util="frame"] .PinturaUtilFooter > div {
  padding: 0;
}

.PinturaRoot[data-env~="is-compact"] .PinturaUtilPanel[data-util="frame"] .PinturaRadioGroupOption {
  width: 2.25em;
  height: 3em;
}

.PinturaRoot[data-env~="is-compact"] .PinturaUtilPanel[data-util="frame"] .PinturaRadioGroupOption span {
  font-size: 0.5em;
}

.PinturaRoot[data-env~="is-compact"]
  .PinturaUtilPanel[data-util="frame"]
  [data-layout="row"]
  > .PinturaRadioGroupOptions
  > li {
  margin: 0 0.25em;
}

.PinturaRoot[data-env~="is-compact"]
  .PinturaUtilPanel[data-util="frame"]
  [data-layout="row"]
  > .PinturaRadioGroupOptions
  > li:first-child {
  margin-left: 0;
}

.PinturaRoot[data-env~="is-compact"]
  .PinturaUtilPanel[data-util="frame"]
  [data-layout="row"]
  > .PinturaRadioGroupOptions
  > li:last-child {
  margin-right: 0;
}

.PinturaUtilPanel[data-util="filter"] .PinturaUtilFooter {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.PinturaUtilPanel[data-util="filter"] .PinturaScrollable {
  width: 40em;
}

.PinturaUtilPanel[data-util="filter"] [data-layout="row"] > .PinturaRadioGroupOptions > li {
  margin: 0 0.5em;
}

.PinturaUtilPanel[data-util="filter"] [data-layout="row"] > .PinturaRadioGroupOptions > li:first-child {
  margin-left: 0;
}

.PinturaUtilPanel[data-util="filter"] [data-layout="row"] > .PinturaRadioGroupOptions > li:last-child {
  margin-right: 0;
}

.PinturaUtilPanel[data-util="filter"] .PinturaRadioGroupOptionGroupLabel {
  width: 1px;
  height: 1px;
  position: absolute;
  font-size: 0;
  pointer-events: none;
}

.PinturaUtilPanel[data-util="filter"] .PinturaFilterPreview {
  border-radius: 0;
  transition: border-radius 1ms;
}

.PinturaUtilPanel[data-util="filter"] .PinturaFilterOption {
  border-radius: 0;
}

.PinturaUtilPanel[data-util="filter"]
  .PinturaRadioGroupOptions
  .PinturaRadioGroupOption:first-of-type
  .PinturaFilterPreview {
  border-top-left-radius: var(--border-radius);
  border-bottom-left-radius: var(--border-radius);
}

.PinturaUtilPanel[data-util="filter"]
  .PinturaRadioGroupOptions
  .PinturaRadioGroupOption:first-of-type
  .PinturaFilterOption {
  border-bottom-left-radius: var(--border-radius);
}

.PinturaUtilPanel[data-util="filter"]
  .PinturaRadioGroupOptions
  .PinturaRadioGroupOption:last-of-type
  .PinturaFilterPreview {
  border-top-right-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
}

.PinturaUtilPanel[data-util="filter"]
  .PinturaRadioGroupOptions
  .PinturaRadioGroupOption:last-of-type
  .PinturaFilterOption {
  border-bottom-right-radius: var(--border-radius);
}

.PinturaUtilPanel[data-util="filter"] .PinturaRadioGroupOptionGroup {
  display: flex;
  flex-direction: column-reverse;
}

.PinturaUtilPanel[data-util="filter"] .PinturaFilterOption {
  cursor: var(--button-cursor);
  margin-bottom: 1em;
}

.PinturaUtilPanel[data-util="filter"] .PinturaFilterOption span {
  display: block;
  text-align: center;
  padding: 0 0.3125em;
  line-height: 1.75;
  max-width: 100%;
  font-size: 0.625em;
  font-weight: 400;
  margin-left: 1px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.PinturaUtilPanel[data-util="filter"] .PinturaFilterPreview:before {
  transition: background-color var(--transition-duration-10), color var(--transition-duration-10);
}

.PinturaUtilPanel[data-util="filter"] [data-selected="true"] .PinturaFilterOption .PinturaFilterPreview {
  position: relative;
  z-index: 1;
}

.PinturaUtilPanel[data-util="filter"] [data-selected="true"] .PinturaFilterOption .PinturaFilterPreview:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.35;
  border-radius: inherit;
  background-color: var(--color-primary);
}

.PinturaUtilPanel[data-util="filter"] [data-focus-visible] + label > .PinturaFilterOption span {
  background-color: var(--color-focus-50);
  box-shadow: inset 0 0 0 1px var(--color-focus-100);
  border-radius: 0.25em;
}

.PinturaUtilPanel[data-util="filter"] .PinturaFilterOption,
.PinturaUtilPanel[data-util="filter"] .PinturaFilterPreview {
  width: 3.75em;
  height: 4.5em;
}

.PinturaRoot[data-env~="is-compact"] .PinturaUtilPanel[data-util="filter"] .PinturaUtilFooter > div {
  padding: 0;
}

.PinturaRoot[data-env~="is-compact"] .PinturaUtilPanel[data-util="filter"] .PinturaFilterOption,
.PinturaRoot[data-env~="is-compact"] .PinturaUtilPanel[data-util="filter"] .PinturaFilterPreview {
  width: 2.875em;
  height: 3.5em;
}

.PinturaRoot[data-env~="is-compact"] .PinturaUtilPanel[data-util="filter"] .PinturaFilterOption span {
  font-size: 0.5em;
}

.PinturaUtilPanel[data-util="resize"] form {
  display: flex;
  justify-content: center;
  padding: 0 1em;
}

.PinturaUtilPanel[data-util="resize"] .PinturaFormInner {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.PinturaUtilPanel[data-util="resize"] .PinturaFormInner > button {
  position: absolute;
  left: 100%;
  margin-left: 1em;
}

.PinturaUtilPanel[data-util="resize"] .PinturaFieldsetInner {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.PinturaUtilPanel[data-util="resize"] .PinturaFieldsetInner > * {
  margin: 0.25em;
}

.PinturaUtilPanel[data-util="resize"] .PinturaResizeLabel {
  font-size: 0.75em;
}

.PinturaUtilPanel[data-util="resize"] .PinturaDropdownButton {
  margin-left: 0.5em;
  margin-right: 0.5em;
  background-color: var(--color-foreground-5);
  border-radius: var(--border-radius-round);
  box-shadow: inset 0 0 0 1px var(--color-foreground-5);
  transition: background-color var(--transition-duration-10);
}

.PinturaUtilPanel[data-util="resize"] .PinturaDropdownButton:hover {
  background-color: var(--color-foreground-10);
}

.PinturaUtilPanel[data-util="resize"] .PinturaDropdownButton .PinturaButtonLabel {
  display: block;
  line-height: 2.65;
  padding: 0 1em;
  font-variant-numeric: tabular-nums;
  font-size: 0.75em;
  font-weight: 400;
}

.PinturaUtilPanel[data-util="resize"] .PinturaDropdownButton .PinturaButtonLabel:after {
  content: "▼";
  font-size: 0.6875em;
  margin-left: 0.75em;
  position: relative;
  top: -0.125em;
  margin-right: -0.25em;
  pointer-events: none;
}

.PinturaUtilPanel[data-util="resize"] input[type="checkbox"] + label {
  padding: 0 0.125em;
  margin: 0 -0.25em;
  outline: transparent;
  display: block;
  cursor: pointer;
}

.PinturaUtilPanel[data-util="resize"] input[type="checkbox"][data-focus-visible] + label {
  background-color: var(--color-focus-50);
  box-shadow: inset 0 0 0 1px var(--color-focus-100);
}

.PinturaInputDimension {
  display: inline-flex;
  align-items: center;
  position: relative;
}

.PinturaInputDimension label {
  position: absolute;
  right: 0.625em;
  font-size: 0.75em;
  text-transform: uppercase;
  pointer-events: none;
  color: var(--color-foreground-80);
  z-index: 1;
}

.PinturaInputDimension button,
.PinturaInputDimension input,
.PinturaInputDimension label {
  font-size: 0.75em;
  font-weight: 400;
}

.PinturaInputDimension button,
.PinturaInputDimension input {
  -webkit-appearance: none;
  appearance: none;
  -moz-appearance: textfield;
  box-sizing: border-box;
  font-variant-numeric: tabular-nums;
  padding: 0.625em 0 0.625em 0.625em;
  width: 100%;
  max-width: 7em;
  outline: transparent;
  border-radius: var(--border-radius);
  box-shadow: none;
  border: 1px solid var(--color-foreground-80);
  transition: background-color var(--transition-duration-10) ease-in-out,
    color var(--transition-duration-10) ease-in-out, border-color var(--transition-duration-10) ease-in-out;
}

.PinturaInputDimension button::-moz-selection,
.PinturaInputDimension input::-moz-selection {
  background-color: var(--color-foreground-20);
}

.PinturaInputDimension button::selection,
.PinturaInputDimension input::selection {
  background-color: var(--color-foreground-20);
}

.PinturaInputDimension button:hover,
.PinturaInputDimension input:hover {
  background-color: var(--color-foreground-10);
}

.PinturaInputDimension button:focus,
.PinturaInputDimension input:focus {
  border-color: var(--color-focus-100);
}

.PinturaInputDimension input {
  background-color: var(--color-foreground-5);
}

.PinturaInputDimension input:-moz-placeholder-shown + label {
  color: var(--color-foreground-20);
}

.PinturaInputDimension input:-ms-input-placeholder + label {
  color: var(--color-foreground-20);
}

.PinturaInputDimension input:placeholder-shown + label {
  color: var(--color-foreground-20);
}

.PinturaInputDimension input::-moz-placeholder {
  color: var(--color-foreground-30);
}

.PinturaInputDimension input:-ms-input-placeholder {
  color: var(--color-foreground-30);
}

.PinturaInputDimension input::placeholder {
  color: var(--color-foreground-30);
}

.PinturaInputDimension input[data-state="invalid"] {
  border-color: var(--color-error-75);
  box-shadow: inset 0 0 0.25em var(--color-error-25), 0 0 0.25em var(--color-error-50);
}

.PinturaInputDimension button {
  width: 10em;
  min-height: 2.65em;
}

.PinturaInputDimension input::-webkit-inner-spin-button,
.PinturaInputDimension input::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.PinturaInputDimension input::-ms-clear {
  display: none;
}

.PinturaRoot[dir="rtl"] .PinturaInputDimension label {
  left: 0.625em;
  right: auto;
}

.PinturaRoot[dir="rtl"] .PinturaInputDimension input {
  padding-right: 0.625em;
  padding-left: 0;
  text-align: right;
}

.PinturaUtilPanel[data-util="crop"] .PinturaStage {
  touch-action: none;
}

.PinturaUtilPanel[data-util="crop"],
.PinturaUtilPanel[data-util="crop"] .PinturaStage,
.PinturaUtilPanel[data-util="crop"] .PinturaUtilMain {
  pointer-events: all;
}

.PinturaUtilPanel[data-util="crop"] .PinturaImageInfo {
  position: absolute;
  right: 1em;
  bottom: 1em;
}

.PinturaUtilPanel[data-util="crop"] .PinturaButtonCenter {
  --size: 3em;
  position: absolute;
  left: 0;
  top: 0;
  width: var(--size);
  height: var(--size);
  z-index: 1;
  line-height: 0;
  margin-top: calc(var(--size) * (-0.5));
  margin-left: calc(var(--size) * (-0.5));
  overflow: hidden;
  border-radius: 50%;
  color: #000;
  background: hsla(0, 0%, 100%, 0.8);
  -webkit-backdrop-filter: var(--backdrop-filter-bright);
  backdrop-filter: var(--backdrop-filter-bright);
  outline: none;
}

.PinturaUtilPanel[data-util="crop"] .PinturaButtonCenter[data-focus-visible] {
  background-color: var(--color-focus-50);
  box-shadow: inset 0 0 0 1px var(--color-focus-100);
}

.PinturaRoot[data-env~="short"] .PinturaUtilPanel[data-util="crop"] .PinturaUtilFooter {
  padding-bottom: 0.5em;
}

.PinturaImageInfo,
.PinturaRoot[data-env~="overlay"] .PinturaUtilPanel[data-util="crop"] .PinturaUtilFooter,
.PinturaRoot[data-env~="overlay"] .PinturaUtilPanel[data-util="crop"] .PinturaUtilFooter > [slot]:empty {
  pointer-events: none;
}

.PinturaImageInfo p {
  font-variant-numeric: tabular-nums;
  font-feature-settings: "tnum";
  font-family: Tahoma, Geneva, Verdana, sans-serif;
  text-align: right;
  line-height: 1;
  font-size: 0.75em;
}

.PinturaImageInfo:not([data-custom]) p {
  width: 8em;
  height: 1.25em;
  white-space: nowrap;
  contain: strict;
  overflow: hidden;
}

.PinturaUtilPanel[data-util="fill"] [data-layout="row"] > .PinturaRadioGroupOptions > li {
  margin: 0 0.25em;
}

.PinturaUtilPanel[data-util="fill"] [data-layout="row"] > .PinturaRadioGroupOptions > li:first-child {
  margin-left: 0;
}

.PinturaUtilPanel[data-util="fill"] [data-layout="row"] > .PinturaRadioGroupOptions > li:last-child {
  margin-right: 0;
}

.PinturaUtilPanel[data-util="fill"] .PinturaRadioGroupOption {
  position: relative;
  width: 3em;
  height: 4em;
}

.PinturaUtilPanel[data-util="fill"] .PinturaFillTile {
  position: absolute;
  width: 100%;
  height: 100%;
}

.PinturaUtilPanel[data-util="fill"] .PinturaFillTile[data-transparent="true"] {
  background-color: #fff;
  background-image: var(--pattern-transparent);
  border-radius: calc(var(--border-radius) + 1px);
}

.PinturaUtilPanel[data-util="fill"] .PinturaFillTile div,
.PinturaUtilPanel[data-util="fill"] .PinturaFillTile img {
  cursor: var(--button-cursor);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: var(--border-radius);
}

.PinturaUtilPanel[data-util="fill"] .PinturaFillTile:after {
  content: "";
  z-index: 3;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  box-shadow: inset 0 0 0 1px var(--color-foreground-5);
  border-radius: var(--border-radius);
  pointer-events: none;
}

.PinturaUtilPanel[data-util="fill"] .PinturaRadioGroupOption[data-selected="true"] .PinturaFillTile:after {
  box-shadow: inset 0 0 0 1px var(--color-foreground-5), inset 0 0 0 4px var(--color-primary),
    inset 0 0 0 5px var(--color-foreground-5);
}

.PinturaRoot[data-env~="is-compact"] .PinturaUtilPanel[data-util="fill"] .PinturaUtilFooter > div {
  padding: 0;
}

.PinturaRoot[data-env~="is-compact"] .PinturaUtilPanel[data-util="fill"] .PinturaRadioGroupOption {
  width: 2.25em;
  height: 3em;
}

.PinturaRoot[data-env~="is-compact"] .PinturaUtilPanel[data-util="fill"] .PinturaRadioGroupOption span {
  font-size: 0.5em;
}

.PinturaRoot[data-env~="is-compact"]
  .PinturaUtilPanel[data-util="fill"]
  [data-layout="row"]
  > .PinturaRadioGroupOptions
  > li {
  margin: 0 0.175em;
}

.PinturaRoot[data-env~="is-compact"]
  .PinturaUtilPanel[data-util="fill"]
  [data-layout="row"]
  > .PinturaRadioGroupOptions
  > li:first-child {
  margin-left: 0;
}

.PinturaRoot[data-env~="is-compact"]
  .PinturaUtilPanel[data-util="fill"]
  [data-layout="row"]
  > .PinturaRadioGroupOptions
  > li:last-child {
  margin-right: 0;
}

.PinturaModal {
  --safe-area-inset-top: env(safe-area-inset-top);
  --safe-area-inset-bottom: env(safe-area-inset-bottom);
  --editor-modal-border-radius: 0;
  --editor-modal-overlay-opacity: 0.95;
  --editor-modal-shadow: 0 1em 2em -1em rgba(0, 0, 0, 0.25), 0 1.5em 1.5em -2em rgba(0, 0, 0, 0.5);
  --editor-modal-outline: inset 0 0 0 1px var(--color-foreground-3);
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: 2147483646;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(
    var(--color-background),
    var(--editor-modal-overlay-opacity, var(--editor-modal-overlay-opacity-default, 0.95))
  );
}

.PinturaModal > .PinturaRoot[data-env~="is-centered"] {
  box-shadow: var(--editor-modal-shadow);
  border-radius: var(--editor-modal-border-radius);
}

.PinturaModal > .PinturaRoot[data-env~="is-centered"]:after {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  pointer-events: none;
  border-radius: inherit;
  content: "";
  box-shadow: var(--editor-modal-outline);
  z-index: 2147483646;
}

.PinturaModal[style*="--viewport-pad-footer:1"] {
  box-sizing: border-box;
  padding-bottom: env(safe-area-inset-bottom);
}

.PinturaModal + .PinturaModal {
  --editor-modal-overlay-opacity-default: 0.75;
}

@media (min-width: 600px) and (min-height: 600px) {
  .PinturaModal + .PinturaModal .PinturaRoot {
    --editor-max-width-default: calc(100vw - 8em);
    --editor-max-height-default: calc(100vh - 8em);
  }
}

.PinturaDocumentLock,
.PinturaDocumentLock body {
  height: calc(var(--pintura-document-height) - 1px);
  overflow-y: hidden;
  box-sizing: border-box;
}

.PinturaDocumentLock body {
  position: relative;
}

.PinturaDocumentLock .PinturaModal {
  height: 100% !important;
}

.PinturaTabPanels {
  flex: 1;
  display: grid;
  grid-template-columns: 1fr;
}

.PinturaTabPanel {
  position: relative;
  grid-row-start: 1;
  grid-column-start: 1;
  min-width: 0;
}

.PinturaTabPanel:not([hidden]) {
  z-index: 2;
  outline: transparent;
}

.PinturaTabPanel[hidden] {
  display: block !important;
  z-index: 1;
  pointer-events: none;
}

.PinturaTabPanel[data-inert="true"] {
  visibility: hidden;
}

@supports not (aspect-ratio: 1) {
  .PinturaTabPanel {
    overflow-x: hidden;
  }
}

.PinturaTabList {
  display: flex;
  justify-content: center;
}

.PinturaTabList button {
  display: flex;
  align-items: center;
  flex-direction: column;
  outline: transparent;
  cursor: var(--button-cursor);
  -webkit-tap-highlight-color: transparent;
}

.PinturaTabList button > * {
  transition: filter var(--transition-duration-10) ease-out;
}

.PinturaTabList button[disabled] > * {
  filter: var(--filter-disabled);
}

.PinturaScrollable {
  --scrollable-feather-start-opacity: 0;
  --scrollable-feather-end-opacity: 0;
  --scrollable-feather-size: 2em;
  contain: paint;
  overflow: hidden;
}

.PinturaScrollable:focus {
  outline: transparent;
}

.PinturaScrollable > div {
  position: relative;
  display: inline-flex;
  vertical-align: top;
}

.PinturaScrollable {
  touch-action: none;
}

.PinturaScrollable[data-direction="x"] {
  max-width: 100%;
  --mask-angle: 90deg;
}

.PinturaScrollable[data-direction="y"] {
  max-height: 100%;
  --mask-angle: 180deg;
}

.PinturaScrollable[data-state~="overflows"] {
  -webkit-mask: linear-gradient(
    var(--mask-angle),
    rgba(0, 0, 0, var(--scrollable-feather-start-opacity)),
    #000 var(--scrollable-feather-size),
    #000 calc(100% - var(--scrollable-feather-size)),
    rgba(0, 0, 0, var(--scrollable-feather-end-opacity))
  );
  mask: linear-gradient(
    var(--mask-angle),
    rgba(0, 0, 0, var(--scrollable-feather-start-opacity)),
    #000 var(--scrollable-feather-size),
    #000 calc(100% - var(--scrollable-feather-size)),
    rgba(0, 0, 0, var(--scrollable-feather-end-opacity))
  );
}

.PinturaScrollable[data-state~="scrolling"] > div * {
  pointer-events: none;
}

.PinturaUtilPanel {
  position: relative;
  z-index: 1;
  height: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  contain: layout size;
  box-sizing: border-box;
}

.PinturaUtilPanel,
.PinturaUtilPanel > * {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}

.PinturaProgressIndicator {
  position: relative;
  display: block;
  width: 1.25em;
  height: 1.25em;
}

.PinturaProgressIndicator svg {
  pointer-events: none;
  vertical-align: top;
}

.PinturaProgressIndicator .PinturaProgressIndicatorFill {
  stroke-dashoffset: 0;
}

.PinturaProgressIndicator[data-status="busy"] svg {
  -webkit-animation: image_editor_spin 1s linear infinite;
  animation: image_editor_spin 1s linear infinite;
}

.PinturaProgressIndicator[data-status="busy"] .PinturaProgressIndicatorFill {
  stroke-dashoffset: -26.5;
  stroke-dasharray: 26.5 53;
  transition: stroke-dashoffset 0.5s linear, stroke-dasharray 0.5s linear;
}

@-webkit-keyframes image_editor_spin {
  0% {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(1turn);
  }
}

@keyframes image_editor_spin {
  0% {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(1turn);
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 3) {
  .PinturaRoot[data-env~="is-ios"] .PinturaProgressIndicator svg {
    transform-origin: 49% 49%;
  }
}

.PinturaButton {
  white-space: nowrap;
  flex-shrink: 0;
  -webkit-tap-highlight-color: transparent;
  cursor: var(--button-cursor);
}

.PinturaButton > span {
  display: inline-flex;
  align-items: center;
  vertical-align: top;
  min-height: 1em;
}

.PinturaButton svg + .PinturaButtonLabel {
  margin-left: 0.5em;
}

.PinturaButton[disabled] {
  cursor: default;
  filter: var(--filter-disabled);
}

.PinturaButtonInner {
  text-decoration: inherit;
}

.PinturaButton:not(.PinturaButtonIconOnly) svg:first-of-type {
  margin-left: -0.25em;
}

.PinturaRoot[dir="rtl"] .PinturaButton:not(.PinturaButtonIconOnly) svg:first-of-type {
  margin-right: -0.25em;
}

.PinturaRoot[dir="rtl"] .PinturaButton svg + .PinturaButtonLabel {
  margin-left: 0;
  margin-right: 1em;
}

.PinturaInputForm {
  opacity: 0;
  cursor: auto;
  z-index: 2147483647;
  --width: 30em;
}

.PinturaInputForm:not([data-stick="true"]) {
  position: absolute;
  bottom: 7.5em;
  width: var(--width);
  left: calc(50% - var(--width) / 2);
  box-shadow: 0 0.25em 0.5em -0.5em rgba(0, 0, 0, 0.75), inset 0 0 0 1px var(--color-foreground-5);
  border-radius: var(--border-radius);
  background-color: var(--color-background-95);
}

@media (max-width: 34em) {
  .PinturaInputForm:not([data-stick="true"]) {
    left: 2em;
    right: 2em;
    width: auto;
  }
}

.PinturaInputForm[data-stick="true"] {
  transition: opacity 0.15s ease 0.25s;
  position: absolute;
  left: 0;
  right: 0;
  outline: 1px solid var(--color-foreground-15);
  background-color: var(--color-background-100);
}

.PinturaInputForm[data-stick="true"] > .PinturaInputFormInner {
  background-color: var(--color-foreground-3);
}

.PinturaInputFormInner > .PinturaButton {
  color: var(--color-foreground-100);
  border-radius: 9999em;
  box-shadow: inset 0 0 0 1px var(--color-foreground-5);
}

.PinturaInputFormInner > .PinturaButton:first-of-type {
  left: 1em;
}

.PinturaInputFormInner > .PinturaButton:last-of-type {
  right: 1rem;
  color: var(--color-primary-text);
  background: var(--color-primary);
  box-shadow: inset 0 0 0 1px var(--color-foreground-5);
}

.PinturaInputFormInner > .PinturaButton:not(.PinturaButtonIconOnly) .PinturaButtonInner {
  padding: 0 0.875em;
}

.PinturaInputFormInner > .PinturaButton .PinturaButtonIcon {
  width: 0.875em;
  height: 0.875em;
}

.PinturaInputFormInner > .PinturaButton.PinturaButtonIconOnly {
  width: 1.875em;
  height: 1.875em;
}

.PinturaInputFormInner > .PinturaButton .PinturaButtonLabel {
  font-size: 0.75em;
}

.PinturaInputFormInner > .PinturaButton .PinturaButtonInner {
  line-height: 2;
  display: flex;
  justify-content: center;
}

.PinturaInputFormInner {
  padding-top: 1em;
  padding-bottom: 3.5em;
}

.PinturaInputFormInner > .PinturaButton {
  position: absolute;
  bottom: 1em;
}

.PinturaInputFormFields textarea:only-child {
  height: 5em;
  -webkit-mask: linear-gradient(0deg, transparent, #000 1em);
  mask: linear-gradient(0deg, transparent, #000 1em);
}

.PinturaInputFormFields textarea:only-child::-webkit-scrollbar {
  width: 1em;
  cursor: pointer;
}

.PinturaInputFormFields textarea:only-child::-webkit-scrollbar-track {
  background: none;
}

.PinturaInputFormFields textarea:only-child::-webkit-scrollbar-thumb {
  cursor: pointer;
  background-clip: padding-box;
  background-color: var(--color-foreground-70);
  border-radius: 9999em;
  border: 0.25em solid transparent;
}

.PinturaInputFormFields input:only-child,
.PinturaInputFormFields textarea:only-child {
  width: 100%;
  padding: 0 1em 0.5em;
  display: block;
  background: transparent;
}

.PinturaInputForm input,
.PinturaInputForm textarea {
  box-sizing: border-box;
  resize: none;
  border: none;
  font-size: 0.875em;
  outline: transparent;
  color: var(--color-foreground-100);
}

.PinturaInputForm input {
  background-color: var(--color-background-100);
}

.PinturaModalBodyLock .PinturaInputForm {
  margin-top: -50px;
}

.PinturaInputForm[data-layout="row"] {
  --width: 30em;
  width: var(--width);
  max-width: calc(100% - 2em);
  border-radius: 9999em;
}

.PinturaInputForm[data-layout="row"] .PinturaInputFormFields {
  flex: 1;
}

.PinturaInputForm[data-layout="row"] .PinturaInputFormInner {
  display: flex;
  padding: 0.5em;
}

.PinturaInputForm[data-layout="row"] .PinturaInputFormInner input {
  padding: 0 0.5em;
  line-height: 1.75;
  font-size: 0.8125em;
  flex: 1;
}

.PinturaInputForm[data-layout="row"] .PinturaInputFormInner .PinturaButton:first-child {
  margin-right: 0;
}

.PinturaInputForm[data-layout="row"] .PinturaInputFormInner .PinturaButton {
  position: static;
}

.PinturaInputForm[data-layout="row"] .PinturaInputFormInner .PinturaButtonIconOnly {
  width: 1.5em;
  height: 1.5em;
}

.PinturaInputForm[data-layout="row"] .PinturaInputFormInner .PinturaButtonLabel {
  font-size: 0.625em;
}

.PinturaUtilMain {
  padding-left: 1em;
  padding-right: 1em;
  min-height: 1px;
  touch-action: none;
}

.PinturaUtilMain,
.PinturaUtilMain > [slot] {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.PinturaUtilMain > [slot] {
  align-items: center;
}

.PinturaUtilFooter > [slot]:empty {
  min-height: 1em;
}

.PinturaUtilFooter > [slot] {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: stretch;
  pointer-events: all;
}

.PinturaUtilFooter .PinturaControlPanel .PinturaImageButtonList,
.PinturaUtilFooter .PinturaShapeStyleList {
  min-height: 3.5em;
  align-items: flex-start;
}

.PinturaUtilFooter .PinturaControlList [role="tab"][aria-selected="true"] {
  background-color: var(--color-foreground-10);
  box-shadow: inset 0 0 0 1px var(--color-foreground-5);
}

.PinturaUtilFooter .PinturaControlList .PinturaControlListOption label,
.PinturaUtilFooter .PinturaControlList [role="tab"] {
  flex-direction: row;
  line-height: 1;
  padding: 0 0.75em;
  min-height: 1.75em;
  white-space: nowrap;
  border-radius: var(--border-radius-round);
  transition: background-color var(--transition-duration-10) ease-out, color var(--transition-duration-10) ease-out,
    filter var(--transition-duration-25) ease-out;
  box-shadow: inset 0 0 0 1px var(--color-foreground-0);
  cursor: var(--button-cursor);
}

.PinturaUtilFooter .PinturaControlList .PinturaControlListOption label span,
.PinturaUtilFooter .PinturaControlList [role="tab"] span {
  font-size: 0.75em;
  font-weight: 400;
}

.PinturaUtilFooter .PinturaControlList .PinturaControlListOption label svg,
.PinturaUtilFooter .PinturaControlList [role="tab"] svg {
  width: 0.875em;
  font-size: 0.875em;
}

.PinturaUtilFooter .PinturaControlList .PinturaControlListOption label svg:only-child,
.PinturaUtilFooter .PinturaControlList [role="tab"] svg:only-child {
  margin-left: -0.25em;
  margin-right: -0.25em;
}

.PinturaUtilFooter .PinturaControlList .PinturaControlListOption label svg:not(:only-child),
.PinturaUtilFooter .PinturaControlList [role="tab"] svg:not(:only-child) {
  margin-left: -0.25em;
  margin-right: 0.375em;
}

.PinturaUtilFooter .PinturaControlList .PinturaControlListOption label:hover,
.PinturaUtilFooter .PinturaControlList [role="tab"]:hover {
  box-shadow: inset 0 0 0 1px var(--color-foreground-10);
}

.PinturaUtilFooter .PinturaControlList .PinturaControlListOption label[data-focus-visible],
.PinturaUtilFooter .PinturaControlList [role="tab"][data-focus-visible] {
  background-color: var(--color-focus-50);
  box-shadow: inset 0 0 0 1px var(--color-focus-100);
}

.PinturaUtilFooter .PinturaControlListOption [slot],
.PinturaUtilFooter .PinturaControlListOption label {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.PinturaUtilFooter .PinturaControlListOption[data-selected="true"] label {
  background-color: var(--color-foreground-10);
  box-shadow: inset 0 0 0 1px var(--color-foreground-5);
}

.PinturaUtilFooter .PinturaControlListOption input[data-focus-visible] + label {
  background-color: var(--color-focus-50);
  box-shadow: inset 0 0 0 1px var(--color-focus-100);
}

.PinturaUtilFooter .PinturaControlListOption input[disabled] + label {
  filter: var(--filter-disabled);
}

.PinturaUtilFooter .PinturaControlList .PinturaControlListOption + .PinturaControlListOption,
.PinturaUtilFooter .PinturaControlList > button + button {
  margin-left: 0.5em;
}

.PinturaUtilFooter .PinturaControlPanels {
  display: grid;
  grid-template-columns: 1fr;
  width: 40em;
  max-width: 100%;
  margin: 0 auto;
  flex: auto;
}

.PinturaUtilFooter .PinturaControlPanel {
  grid-row-start: 1;
  grid-column-start: 1;
  max-width: 100%;
  overflow: hidden;
  visibility: visible;
  opacity: 1;
}

.PinturaUtilFooter .PinturaControlPanel[hidden] {
  display: block !important;
  visibility: hidden;
  transition-delay: 0s;
  pointer-events: none;
  opacity: 0;
}

.PinturaUtilFooter .PinturaControlList[data-layout="compact"] > * + * {
  margin-left: -1px;
}

.PinturaUtilFooter .PinturaControlList[data-layout="compact"] > [role="tab"] {
  padding-left: 0.625em;
  padding-right: 0.625em;
  border-radius: 0;
  box-shadow: inset 0 0 0 1px var(--color-foreground-5);
}

.PinturaUtilFooter .PinturaControlList[data-layout="compact"] > [role="tab"]:only-child {
  border-radius: var(--border-radius-round);
}

.PinturaUtilFooter .PinturaControlList[data-layout="compact"] > [role="tab"]:first-child:not(:only-child) {
  padding-left: 0.75em;
  border-top-left-radius: var(--border-radius-round);
  border-bottom-left-radius: var(--border-radius-round);
}

.PinturaUtilFooter .PinturaControlList[data-layout="compact"] > [role="tab"]:last-child:not(:only-child) {
  padding-right: 0.75em;
  border-top-right-radius: var(--border-radius-round);
  border-bottom-right-radius: var(--border-radius-round);
}

@supports not (aspect-ratio: 1) {
  .PinturaControlPanel {
    overflow-x: hidden;
  }
}

.PinturaRoot[data-env~="is-animated"] .PinturaUtilFooter .PinturaControlPanel {
  transition: opacity var(--transition-duration-25) ease-in-out, visibility var(--transition-duration-25) linear;
  transition-delay: 0.1s;
}

.PinturaRoot[data-env~="has-controlgroups-preference-top"] .PinturaUtilHeader {
  order: 1;
}

.PinturaRoot[data-env~="has-controlgroups-preference-top"] .PinturaUtilFooter {
  order: 2;
}

.PinturaRoot[data-env~="has-controlgroups-preference-top"] .PinturaUtilMain {
  order: 3;
}

.PinturaRoot[data-env~="has-controltabs-preference-bottom"] .PinturaUtilFooter .PinturaControlPanels {
  order: -1;
}

.PinturaRoot[data-env~="has-controltabs-preference-bottom"]
  .PinturaUtilFooter
  .PinturaRangeInput
  .PinturaRangeInputReset {
  top: 0;
  bottom: auto;
}

.PinturaRoot[data-env~="has-controltabs-preference-bottom"]
  .PinturaUtilFooter
  .PinturaControlPanel
  .PinturaShapePresetsGrouped {
  padding-bottom: 0.75em;
}

.PinturaRoot[data-env~="has-controltabs-preference-top"] .PinturaUtilFooter .PinturaControlPanels {
  order: 1;
}

.PinturaRoot[data-env~="has-controltabs-preference-top"] .PinturaUtilFooter .PinturaRangeInput .PinturaRangeInputReset {
  top: auto;
  bottom: 0;
}

.PinturaRoot[data-env~="has-controltabs-preference-top"]
  .PinturaUtilFooter
  .PinturaControlPanel
  .PinturaShapePresetsGrouped {
  padding-top: 0.75em;
}

.PinturaRoot[data-env~="has-controltabs-preference-top"]
  .PinturaUtilFooter
  .PinturaControlPanel
  .PinturaImageButtonList,
.PinturaRoot[data-env~="has-controltabs-preference-top"] .PinturaUtilFooter .PinturaShapeStyleList {
  align-items: flex-end;
}

.PinturaRoot[data-env~="has-controltabs-preference-top"] .PinturaUtilFooter .PinturaShapeStyleLabel {
  order: 1;
}

.PinturaRoot[data-env~="overlay"] .PinturaUtilFooter {
  margin: 0.75em 0;
}

.PinturaRoot[data-env~="overlay"] .PinturaUtilFooter .PinturaControlPanels {
  max-width: 24em;
}

.PinturaRoot[data-env~="overlay"] .PinturaUtilFooter .PinturaControlList {
  margin-top: 1em;
}

.PinturaRoot[data-env~="overlay"] .PinturaUtilFooter .PinturaControlList > button + button {
  margin-left: 0;
}

.PinturaRoot[data-env~="overlay"] .PinturaUtilFooter .PinturaControlList [role="tab"] span {
  font-size: 0.75em;
}

.PinturaRoot[data-env~="overlay"] .PinturaUtilFooter .PinturaControlList [role="tab"] {
  border-radius: 0;
  background-color: var(--color-background-50);
  -webkit-backdrop-filter: var(--backdrop-filter-bright);
  backdrop-filter: var(--backdrop-filter-bright);
  box-shadow: inset 0 0 0 1px var(--color-background-15), 0 0.0625em 0.125em rgba(0, 0, 0, 0.25),
    0 0.125em 0.35em rgba(0, 0, 0, 0.35);
}

.PinturaRoot[data-env~="overlay"] .PinturaUtilFooter .PinturaControlList [role="tab"]:first-child {
  padding-left: 0.875em;
  border-top-left-radius: var(--border-radius-round);
  border-bottom-left-radius: var(--border-radius-round);
}

.PinturaRoot[data-env~="overlay"] .PinturaUtilFooter .PinturaControlList [role="tab"]:last-child {
  padding-right: 0.875em;
  border-top-right-radius: var(--border-radius-round);
  border-bottom-right-radius: var(--border-radius-round);
}

.PinturaRoot[data-env~="overlay"] .PinturaUtilFooter .PinturaControlList button:not([aria-selected="true"]) span {
  color: var(--color-foreground-40);
}

.PinturaRoot[data-env~="overlay"] .PinturaUtilFooter .PinturaControlPanels {
  background-color: var(--color-background-50);
  -webkit-backdrop-filter: var(--backdrop-filter-bright);
  backdrop-filter: var(--backdrop-filter-bright);
  border-radius: var(--border-radius-round);
  box-shadow: inset 0 0 0 1px var(--color-background-15), 0 0.0625em 0.125em rgba(0, 0, 0, 0.25),
    0 0.125em 0.35em rgba(0, 0, 0, 0.35);
}

.PinturaRoot[data-env~="overlay"] .PinturaUtilFooter .PinturaRangeInput {
  height: 1.5em;
  margin-top: 0;
}

.PinturaRoot[data-env~="overlay"] .PinturaUtilFooter .PinturaRangeInputReset {
  opacity: 0;
}

.PinturaRoot[dir="rtl"] .PinturaUtilFooter .PinturaControlListOption [slot],
.PinturaRoot[dir="rtl"] .PinturaUtilFooter .PinturaControlListOption label {
  flex-direction: row-reverse;
}

.PinturaRoot[dir="rtl"] .PinturaUtilFooter .PinturaControlList .PinturaControlListOption label svg:not(:only-child),
.PinturaRoot[dir="rtl"] .PinturaUtilFooter .PinturaControlList [role="tab"] svg:not(:only-child) {
  margin-right: -0.25em;
  margin-left: 0.375em;
}

.PinturaRangeInput {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  height: 3.5em;
  max-width: 24em;
  width: calc(100% - 2em);
  touch-action: none;
  --range-input-feather-left: 2em;
  --range-input-feather-right: 2em;
  --range-input-mask-from: 0%;
  --range-input-mask-to: 100%;
  --range-input-mask-color: rgba(0, 0, 0, 0.25);
  --range-input-line-color: var(--color-foreground-15);
  outline: transparent;
}

.PinturaRangeInput[data-focus-visible]:after {
  background-color: var(--color-focus-50);
  box-shadow: inset 0 0 0 1px var(--color-focus-100);
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: calc(50% - 1em);
  height: 2em;
  border-radius: 0.25em;
}

.PinturaRangeInputInner {
  position: relative;
  overflow: hidden;
  height: 100%;
  width: 100%;
  contain: strict;
  -webkit-mask: linear-gradient(
    90deg,
    transparent 0,
    #000 var(--range-input-feather-left),
    #000 calc(50% - 3em),
    transparent 50%,
    #000 calc(50% + 3em),
    #000 calc(100% - var(--range-input-feather-right)),
    transparent
  );
  mask: linear-gradient(
    90deg,
    transparent 0,
    #000 var(--range-input-feather-left),
    #000 calc(50% - 3em),
    transparent 50%,
    #000 calc(50% + 3em),
    #000 calc(100% - var(--range-input-feather-right)),
    transparent
  );
}

.PinturaRangeInputMeter {
  height: 100%;
}

.PinturaRangeInput .PinturaRangeInputMeter svg {
  display: block;
  pointer-events: none;
  fill: currentColor;
}

.PinturaRangeInput > .PinturaRangeInputValue {
  position: absolute;
  z-index: 1;
  pointer-events: none;
  top: calc(50% - 0.6875em);
  left: calc(50% - 1.75em);
  width: 3.5em;
  height: 1.5em;
  overflow: hidden;
  contain: strict;
  text-align: center;
  font-size: 0.75em;
  font-variant-numeric: tabular-nums;
  font-feature-settings: "tnum";
  font-family: Tahoma, Geneva, Verdana, sans-serif;
}

.PinturaRangeInput > .PinturaRangeInputReset {
  position: absolute;
  left: calc(50% - 1.0625em);
  top: 0;
  margin-top: 0.125em;
  padding: 0;
  z-index: 1;
  width: 2em;
  overflow: hidden;
  color: transparent !important;
  transition: opacity var(--transition-duration-25);
  opacity: 0.35;
  outline: transparent;
  cursor: var(--button-cursor);
}

.PinturaRangeInput > .PinturaRangeInputReset:hover {
  opacity: 0.5;
}

.PinturaRangeInput > .PinturaRangeInputReset[data-focus-visible] {
  opacity: 1;
}

.PinturaRangeInput > .PinturaRangeInputReset[data-focus-visible]:before {
  content: "";
  pointer-events: none;
  position: absolute;
  left: calc(50% - 0.5em);
  top: calc(50% - 0.5em);
  height: 1em;
  width: 1em;
  z-index: 2;
  border-radius: 0.25em;
  background-color: var(--color-focus-50);
  box-shadow: inset 0 0 0 1px var(--color-focus-100);
}

.PinturaRangeInput > .PinturaRangeInputReset[disabled] {
  opacity: 0.15;
}

.PinturaRangeInput > .PinturaRangeInputReset:after {
  content: "";
  position: absolute;
  left: 50%;
  top: 20%;
  width: 1px;
  height: 60%;
  background: var(--color-foreground-100);
}

.PinturaRangeInputInner[data-value-limited] svg {
  -webkit-mask: linear-gradient(
    90deg,
    var(--range-input-mask-color) var(--range-mask-from),
    #000 var(--range-mask-from),
    #000 var(--range-mask-to),
    var(--range-input-mask-color) var(--range-mask-to)
  );
  mask: linear-gradient(
    90deg,
    var(--range-input-mask-color) var(--range-mask-from),
    #000 var(--range-mask-from),
    #000 var(--range-mask-to),
    var(--range-input-mask-color) var(--range-mask-to)
  );
}

.PinturaRangeInputInner[data-value-limited] svg rect {
  x: var(--range-mask-from);
  width: calc(var(--range-mask-to) - var(--range-mask-from));
  fill: var(--range-input-line-color);
}

.PinturaRadioGroup .PinturaRadioGroupOptions {
  display: flex;
  flex-direction: column;
}

.PinturaRadioGroup .PinturaRadioGroupOptionGroup + .PinturaRadioGroupOptionGroup {
  margin-top: 0.5em;
}

.PinturaRadioGroup .PinturaRadioGroupOption[data-hidden="true"] {
  display: none;
}

.PinturaRadioGroup[data-layout="row"] .PinturaRadioGroupOptions {
  flex-direction: row;
}

.PinturaRadioGroup label {
  -webkit-tap-highlight-color: transparent;
}

.PinturaPresetListFilter .PinturaRadioGroupOptions {
  justify-content: space-evenly;
  padding: 0.625em 0 0.3125em;
}

.PinturaPresetListFilter .PinturaRadioGroupOptions label {
  display: block;
  cursor: pointer;
}

.PinturaPresetListFilter + .PinturaOptionsList {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.PinturaRectManipulator {
  --size: 1.25em;
  position: absolute;
  left: 0;
  top: 0;
  outline: none;
  touch-action: none;
  transform: translate3d(calc(var(--tx) * 1px), calc(var(--ty) * 1px), 0) scale(var(--sx), var(--sy));
}

.PinturaRectManipulator:not([data-shape="edge"]) {
  width: var(--size);
  height: var(--size);
  z-index: 2;
}

.PinturaRectManipulator:not([data-shape="edge"]):after {
  position: absolute;
  left: -0.5em;
  right: -0.5em;
  top: -0.5em;
  bottom: -0.5em;
  border-radius: inherit;
  content: "";
}

.PinturaRectManipulator:not([data-shape="edge"])[data-focus-visible]:after {
  background-color: var(--color-focus-50);
  box-shadow: inset 0 0 0 1px var(--color-focus-100);
}

.PinturaRectManipulator[data-shape~="circle"] {
  margin-left: calc(var(--size) * (-0.5));
  margin-top: calc(var(--size) * (-0.5));
  line-height: var(--size);
  border-radius: 50%;
  background-color: var(--color-foreground-100);
  box-shadow: 0 0 2px var(--color-background-50);
}

.PinturaRectManipulator[data-shape="hook"] {
  border: 3px solid var(--color-foreground-100);
}

.PinturaRectManipulator[data-shape="hook"][data-direction*="t"] {
  margin-top: -3px;
  border-bottom: 0;
}

.PinturaRectManipulator[data-shape="hook"][data-direction*="r"] {
  border-left: 0;
  margin-left: calc(var(--size) * (-1));
}

.PinturaRectManipulator[data-shape="hook"][data-direction*="b"] {
  margin-top: calc(var(--size) * (-1));
  border-top: 0;
}

.PinturaRectManipulator[data-shape="hook"][data-direction*="l"] {
  border-right: 0;
  margin-left: -3px;
}

.PinturaRectManipulator[data-shape~="edge"] {
  height: 1px;
  width: 1px;
  z-index: 1;
}

.PinturaRectManipulator[data-shape~="edge"][data-focus-visible] {
  background-color: var(--color-focus-50);
}

.PinturaRectManipulator[data-direction="b"],
.PinturaRectManipulator[data-direction="t"] {
  cursor: ns-resize;
  height: var(--size);
  margin-top: calc(var(--size) * (-0.5));
  transform-origin: 0 center;
}

.PinturaRectManipulator[data-direction="l"],
.PinturaRectManipulator[data-direction="r"] {
  cursor: ew-resize;
  width: var(--size);
  margin-left: calc(var(--size) * (-0.5));
  transform-origin: center 0;
}

.PinturaRectManipulator[data-direction="bl"],
.PinturaRectManipulator[data-direction="tr"] {
  cursor: nesw-resize;
}

.PinturaRectManipulator[data-direction="br"],
.PinturaRectManipulator[data-direction="tl"] {
  cursor: nwse-resize;
}

.PinturaRoot[data-env~="pointer-coarse"] .PinturaRectManipulator[data-shape="edge"]:active {
  background-color: var(--color-foreground-5);
}

.PinturaRoot[data-env~="pointer-coarse"] .PinturaRectManipulator:not([data-shape="edge"]):after {
  left: -1em;
  right: -1em;
  top: -1em;
  bottom: -1em;
}

.PinturaRoot[data-env~="pointer-coarse"] .PinturaRectManipulator:not([data-shape="edge"]):active:after {
  background-color: var(--color-foreground-5);
}

.PinturaRoot[data-env*="chrome-"] .PinturaRectManipulator {
  transform: translate3d(calc(var(--tx) * 1px), calc(var(--ty) * 1px), 0);
}

.PinturaRoot[data-env*="chrome-"] .PinturaRectManipulator[data-direction="b"],
.PinturaRoot[data-env*="chrome-"] .PinturaRectManipulator[data-direction="t"] {
  width: calc(var(--sx) * 1px);
}

.PinturaRoot[data-env*="chrome-"] .PinturaRectManipulator[data-direction="l"],
.PinturaRoot[data-env*="chrome-"] .PinturaRectManipulator[data-direction="r"] {
  height: calc(var(--sy) * 1px);
}

.PinturaToolbar {
  display: flex;
  justify-content: center;
  margin-left: 1em;
  margin-right: 1em;
}

.PinturaToolbar .PinturaToolbarInner {
  display: inline-flex;
}

.PinturaToolbar[data-layout="compact"] .PinturaToolbarContentWide,
.PinturaToolbar[data-overflow="overflow"] .PinturaToolbarContentOptional {
  display: none;
}

.PinturaToolbar .PinturaButton {
  border-radius: var(--border-radius-round);
  outline: transparent;
  padding: 0.3125em 0.75em;
  transition: background-color var(--transition-duration-10) ease-out, color var(--transition-duration-10) ease-out,
    box-shadow var(--transition-duration-10) ease-out;
  line-height: 1.1;
}

.PinturaToolbar .PinturaButton:hover {
  box-shadow: inset 0 0 0 1px var(--color-foreground-5);
}

.PinturaToolbar .PinturaButton[data-focus-visible] {
  background-color: var(--color-focus-50);
  box-shadow: inset 0 0 0 1px var(--color-focus-100);
}

.PinturaToolbar .PinturaButton .PinturaButtonIcon {
  width: 1em;
}

.PinturaToolbar .PinturaButtonLabel {
  font-size: 0.75em;
}

.PinturaToolbar .PinturaPanel {
  z-index: 3;
}

.PinturaToolbar .PinturaToolbarInner > * {
  margin: 0 0.5em;
}

.PinturaShapeStyleEditor {
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
}

.PinturaShapeStyleEditor > div {
  grid-row-start: 1;
  grid-column-start: 1;
  max-width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.PinturaShapeStyleEditorOverflow > div {
  overflow: visible;
  justify-content: flex-start;
}

.PinturaShapeStyleEditorOverflow .PinturaShapeStyleList {
  min-height: auto;
  margin-left: 1em;
}

.PinturaShapeStyleEditorOverflow .PinturaShapeStyle {
  --control-height: 1.75em;
}

.PinturaShapeStyles {
  position: relative;
}

.PinturaShapeStyles:not([data-state~="overflows"]) {
  display: flex;
  justify-content: center;
}

.PinturaShapeStyleList {
  display: flex;
}

.PinturaShapeStyleList .PinturaButton,
.PinturaShapeStyleList .PinturaInput {
  outline: transparent;
}

.PinturaShapeStyleList .PinturaInput {
  padding: 0 0 0 0.625em;
  font-size: 0.75em;
  line-height: 2.25;
}

.PinturaShapeStyleList .PinturaShapeStyleLabel {
  line-height: 1.75;
  font-size: 0.625em;
  font-weight: 300;
  padding: 0 0.75em;
  text-transform: lowercase;
  pointer-events: none;
  text-align: center;
  color: var(--color-foreground-50);
  white-space: nowrap;
}

.PinturaShapeStyleList .PinturaShapeStyle {
  border-radius: var(--border-radius-round);
}

.PinturaShapeStyleList .PinturaShapeStyle + .PinturaShapeStyle {
  margin-left: 1em;
}

.PinturaShapeStyle {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  --control-height: 1.5em;
}

.PinturaShapeStyle .PinturaPanelButton {
  border-radius: inherit;
}

.PinturaShapeStyle > .PinturaButton:hover,
.PinturaShapeStyle > .PinturaButton[data-focus-visible] {
  position: relative;
  z-index: 1;
}

.PinturaShapeStyle .PinturaColorPickerButton .PinturaButtonLabel {
  display: block;
  border-radius: inherit;
}

.PinturaShapeStyle .PinturaColorPickerButton {
  display: flex;
  justify-content: center;
}

.PinturaShapeStyle .PinturaColorPreview {
  border-radius: inherit;
  width: 1.5em;
  height: 1.5em;
  background-color: #fff;
  background-size: 4px;
}

.PinturaShapeStyle .PinturaColorPreview span {
  border-radius: inherit;
  box-shadow: inset 0 0 0 1px var(--color-foreground-5);
}

.PinturaShapeStyle [data-focus-visible] .PinturaColorPreview span:before {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border-radius: inherit;
  background-color: var(--color-focus-50);
  box-shadow: inset 0 0 0 1px var(--color-focus-100);
  content: "";
  z-index: 1;
}

.PinturaShapeStyle > .PinturaColorPickerButtonStroke .PinturaColorPreview {
  -webkit-mask: radial-gradient(circle, transparent 5.5px, #000 6px);
  mask: radial-gradient(circle, transparent 5.5px, #000 6px);
  -webkit-mask-size: cover;
  mask-size: cover;
}

.PinturaShapeStyle > .PinturaColorPickerButtonStroke .PinturaColorPreview span:after {
  position: absolute;
  left: 6px;
  top: 6px;
  right: 6px;
  bottom: 6px;
  border-radius: inherit;
  box-shadow: 0 0 0 1px var(--color-foreground-5);
  content: "";
}

.PinturaShapeStyle > .PinturaSliderButton .PinturaButtonLabel,
.PinturaShapeStyle > .PinturaSliderButton .PinturaButtonLabelPrefix {
  display: block;
  line-height: 2.25;
  font-size: 0.6875em;
}

.PinturaShapeStyle > .PinturaSliderButton .PinturaButtonLabelPrefix {
  color: var(--color-foreground-40);
  padding-left: 0.625em;
  margin-right: -0.25em;
}

.PinturaShapeStyle > .PinturaSliderButton .PinturaButtonLabel {
  padding: 0 1em;
}

.PinturaShapeStyle > .PinturaDropdownButton .PinturaButtonLabel,
.PinturaShapeStyle > .PinturaRadioGroup .PinturaButtonLabel,
.PinturaShapeStyle > .PinturaRadioGroupOption label {
  display: block;
  line-height: 2.25;
  padding: 0 1em;
  font-size: 0.6875em;
}

.PinturaShapeStyle .PinturaButton:not(.PinturaColorPickerButton)[data-focus-visible] {
  background-color: var(--color-focus-50);
  box-shadow: inset 0 0 0 1px var(--color-focus-100);
}

.PinturaShapeStyle .PinturaInputField {
  padding: 0 0.75em;
  width: 3em;
  height: 2em;
  line-height: 2em;
  box-shadow: inset 0 0 0 1px var(--color-foreground-5);
  border-radius: var(--border-radius);
}

.PinturaShapeStyle .PinturaPromptInputGroup {
  position: relative;
  display: flex;
  align-items: flex-start;
}

.PinturaShapeStyle .PinturaPromptInputGroup .PinturaInput {
  width: 18em;
  padding: 0;
}

.PinturaShapeStyle .PinturaPromptInputGroup .PinturaInput input {
  padding-right: 2em;
}

.PinturaShapeStyle .PinturaPromptInputGroup > .PinturaButton:not(.PinturaButtonIconOnly) {
  display: block;
  line-height: 2.125;
  margin-left: 0.5em;
  padding: 0 1em;
  font-size: 0.6875em;
  box-shadow: inset 0 0 0 1px var(--color-foreground-5);
  background-color: var(--color-foreground-10);
  border-radius: var(--border-radius);
}

.PinturaShapeStyle .PinturaPromptInputGroup .PinturaButtonIconOnly {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.75em;
  height: 1.75em;
  padding: 0;
  box-shadow: inset 0 0 0 1px var(--color-foreground-5);
  border-radius: var(--border-radius);
}

.PinturaShapeStyle .PinturaPromptInputGroup .PinturaButtonIconOnly svg {
  width: 0.875em;
}

.PinturaShapeStyle .PinturaPromptInputGroup .PinturaProgressIndicator {
  position: absolute;
  width: 0.875em;
  height: 0.875em;
  pointer-events: none;
}

.PinturaShapeStyle .PinturaPromptInputGroup .PinturaProgressIndicator svg {
  width: 0.875em;
}

.PinturaShapeStyle .PinturaPromptInputWrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.PinturaShapeStyle .PinturaPromptInputWrapper .PinturaProgressIndicator {
  right: 0.3125em;
}

.PinturaShapeStyle .PinturaGenerateMoreButtonWrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.3125em;
  margin-left: 0.75em;
}

.PinturaShapeStyle .PinturaPromptOptions {
  margin-left: 0.5em;
  display: flex;
  align-items: center;
}

.PinturaShapeStyle .PinturaPromptOption {
  position: relative;
  padding: 0;
  margin-left: 0.5em;
  border-radius: 0.5em;
}

.PinturaShapeStyle .PinturaPromptOption:after,
.PinturaShapeStyle .PinturaPromptOption img {
  border-radius: 0.5em;
  pointer-events: none;
}

.PinturaShapeStyle .PinturaPromptOption:after {
  content: "";
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  box-shadow: inset 0 0 0 1px var(--color-foreground-5);
}

.PinturaShapeStyle .PinturaPromptOption img {
  display: block;
  width: 2.5em;
  height: 2.5em;
  -o-object-fit: cover;
  object-fit: cover;
}

.PinturaShapeStyle > .PinturaDropdownButton {
  box-shadow: inset 0 0 0 1px var(--color-foreground-5);
  transition: background-color var(--transition-duration-10);
}

.PinturaShapeStyle > .PinturaDropdownButton:hover {
  background-color: var(--color-foreground-10);
}

.PinturaShapeStyle > .PinturaDropdownButton .PinturaButtonLabel:after,
.PinturaShapeStyle > .PinturaDropdownButton .PinturaComboBox:after {
  content: "▼";
  font-size: 0.6875em;
  margin-left: 0.75em;
  position: relative;
  top: -0.125em;
  margin-right: -0.25em;
  pointer-events: none;
}

.PinturaShapeStyle > .PinturaDropdownButton .PinturaComboBox:after {
  top: 0;
  margin-left: 0.375em;
  font-size: 0.47265625em;
}

.PinturaShapeStyle .PinturaComboBox {
  padding-right: 0.75em;
}

.PinturaShapeStyle .PinturaComboBox .PinturaInput {
  padding-left: 0;
}

.PinturaShapeStyle .PinturaComboBox .PinturaInput input {
  box-shadow: none;
}

.PinturaShapeStyle .PinturaComboBox .PinturaInputField {
  padding-right: 0.375em;
}

.PinturaShapeStyle > .PinturaRadioGroup > .PinturaRadioGroupOptions {
  flex-direction: row;
}

.PinturaShapeStyle > .PinturaRadioGroup > .PinturaRadioGroupOptions label {
  cursor: var(--button-cursor);
  display: flex;
  height: var(--control-height);
  align-items: center;
  padding: 0 0.25em;
  box-shadow: inset 0 0 0 1px var(--color-foreground-5);
  transition: background-color var(--transition-duration-10);
}

.PinturaShapeStyle > .PinturaRadioGroup > .PinturaRadioGroupOptions label:hover {
  background-color: var(--color-foreground-20);
}

.PinturaShapeStyle > .PinturaRadioGroup > .PinturaRadioGroupOptions svg {
  width: 1.125em;
  height: 1.125em;
}

.PinturaShapeStyle > .PinturaRadioGroup > .PinturaRadioGroupOptions .PinturaRadioGroupOption:first-of-type label {
  padding-left: 0.5em;
  border-top-left-radius: var(--border-radius-round);
  border-bottom-left-radius: var(--border-radius-round);
}

.PinturaShapeStyle > .PinturaRadioGroup > .PinturaRadioGroupOptions .PinturaRadioGroupOption:last-of-type label {
  padding-right: 0.5em;
  border-top-right-radius: var(--border-radius-round);
  border-bottom-right-radius: var(--border-radius-round);
}

.PinturaShapeStyle > .PinturaRadioGroup > .PinturaRadioGroupOptions [data-selected="true"] label {
  background-color: var(--color-foreground-10);
}

.PinturaRoot[dir="rtl"] .PinturaShapeStyle > .PinturaDropdownButton .PinturaButtonLabel:after,
.PinturaRoot[dir="rtl"] .PinturaShapeStyle > .PinturaDropdownButton .PinturaComboBox:after {
  margin-right: 0.75em;
  margin-left: -0.25em;
}

.PinturaRoot .PinturaImageButton {
  --thumb-size: 1.75em;
  --thumb-radius: 0.3125em;
  --thumb-margin: 0.3125em;
  padding: var(--thumb-margin);
  border-radius: var(--border-radius);
  box-shadow: inset 0 0 0 1px var(--color-foreground-5);
  cursor: var(--button-cursor);
  outline: transparent;
}

.PinturaRoot .PinturaImageButton > * {
  pointer-events: none;
}

.PinturaRoot .PinturaImageButton {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  transition: filter var(--transition-duration-25) ease-out;
}

.PinturaRoot .PinturaImageButton[disabled] {
  filter: var(--filter-disabled);
}

.PinturaRoot .PinturaImageButton:hover {
  background-color: var(--color-foreground-10);
}

.PinturaRoot .PinturaImageButton text {
  font-family: monospace;
}

.PinturaRoot .PinturaImageButton img,
.PinturaRoot .PinturaImageButton svg {
  display: block;
  width: var(--thumb-size);
  height: var(--thumb-size);
  min-width: auto;
  max-width: none;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: var(--thumb-radius);
}

.PinturaRoot .PinturaImageButton[data-loader]:after,
.PinturaRoot .PinturaImageButton[data-loader]:before {
  display: block;
  width: var(--thumb-size);
  height: var(--thumb-size);
  line-height: var(--thumb-size);
  border-radius: var(--thumb-radius);
}

.PinturaRoot .PinturaImageButton[data-error] img {
  display: none;
}

.PinturaRoot .PinturaImageButton[data-error]:after {
  content: "⚠";
  box-shadow: inset 0 0 0 1px var(--color-error-75);
  background-color: var(--color-error-10);
  color: var(--color-error-75);
}

.PinturaRoot .PinturaImageButton[data-loader] {
  position: relative;
}

.PinturaRoot .PinturaImageButton[data-loader]:before {
  content: "";
  position: absolute;
  left: var(--thumb-margin);
  top: var(--thumb-margin);
  -webkit-animation: pintura-img-loading calc(var(--transition-duration-multiplier, 0) * 1s) infinite alternate
    ease-in-out;
  animation: pintura-img-loading calc(var(--transition-duration-multiplier, 0) * 1s) infinite alternate ease-in-out;
  background-color: var(--color-foreground-10);
  transition: transform calc(var(--transition-duration-multiplier, 0) * 0.5s) ease-in;
  z-index: -1;
}

.PinturaRoot .PinturaImageButton[data-loader] img {
  opacity: 0;
  transition: opacity calc(var(--transition-duration-multiplier, 0) * 0.35s) ease-out;
}

.PinturaRoot .PinturaImageButton[data-loader][data-load] img {
  opacity: 1;
}

.PinturaRoot .PinturaImageButton[data-loader][data-error]:before,
.PinturaRoot .PinturaImageButton[data-loader][data-load]:before {
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
  transform: scale(0.5);
}

@-webkit-keyframes pintura-img-loading {
  0% {
    opacity: 0.5;
  }

  50% {
    opacity: 1;
  }

  to {
    opacity: 0.5;
  }
}

@keyframes pintura-img-loading {
  0% {
    opacity: 0.5;
  }

  50% {
    opacity: 1;
  }

  to {
    opacity: 0.5;
  }
}

.PinturaInput {
  display: flex;
  flex-direction: row;
}

.PinturaInput input[disabled] {
  filter: var(--filter-disabled);
}

.PinturaInputField {
  flex: 1;
  outline: transparent;
}

.PinturaInputField[type="number"] {
  -moz-appearance: textfield;
}

.PinturaInputField[type="number"]::-webkit-inner-spin-button,
.PinturaInputField[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.PinturaInputSpinners {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.PinturaInputSpinners button {
  line-height: 1 !important;
  width: 2em;
  font-size: 0.5em !important;
  outline: transparent;
}

.PinturaPanel.PinturaSliderPanel {
  border-radius: var(--border-radius-round);
}

.PinturaPercentageLabel {
  font-variant-numeric: tabular-nums;
  min-width: 3em;
  text-align: center;
}

.PinturaSliderPanel .PinturaSlider {
  width: 10em;
  --knob-size: 0.75em;
  --knob-margin-left: 0.3125em;
  --knob-margin-right: 0.3125em;
}

.PinturaSliderPanel .PinturaSliderTrack {
  --track-background: var(--color-foreground-10);
}

.PinturaSliderPanel .PinturaSliderKnob {
  --knob-background: var(--color-foreground-100);
  box-shadow: 0 0.125em 0.25em rgba(0, 0, 0, 0.35), 0 -0.125em 0.25em rgba(0, 0, 0, 0.1);
}

.PinturaImageButtonList,
.PinturaImageButtonList > li {
  display: flex;
  align-items: center;
}

.PinturaImageButtonList > li {
  --thumb-size: 1.75em;
  --thumb-radius: 0.3125em;
  --thumb-margin: 0.3125em;
  justify-content: center;
  position: relative;
}

.PinturaImageButtonList > li + li {
  margin-left: 0.5em;
}

.PinturaShorthandControl {
  flex-direction: row;
  padding-left: 0.25em;
  box-shadow: inset 0 0 0 1px var(--color-foreground-5);
}

.PinturaShorthandControl > .PinturaSliderButton + .PinturaSliderButton {
  border-left: 1px solid var(--color-foreground-5);
  border-radius: 0;
}

.PinturaShorthandControl .PinturaSliderButton .PinturaButtonLabel {
  padding: 0 0.5em;
}

.PinturaShorthandControl .PinturaColorPreview {
  width: 1em;
  height: 1em;
  margin-right: 0.25em;
  margin-top: 0.25em;
}

.PinturaShapePresetsPalette {
  text-align: center;
  width: 40em;
  max-width: 100%;
  overflow: hidden;
}

.PinturaShapePresetsPalette .PinturaShapePresetSelect {
  vertical-align: top;
  display: inline-block;
}

.PinturaShapePresetsPalette .PinturaScrollable:not([data-state~="overflows"]) {
  display: flex;
  justify-content: center;
}

.PinturaShapePresetsPalette .PinturaButton + .PinturaButton {
  margin-left: 0.5em;
}

.PinturaShapePresetsPalette .PinturaButton {
  flex-direction: row;
  line-height: 1.1;
  padding: 0.3125em 0.75em;
  border-radius: var(--border-radius-round);
  box-shadow: inset 0 0 0 1px var(--color-foreground-5);
  cursor: var(--button-cursor);
  transition: background-color var(--transition-duration-10) ease-out, color var(--transition-duration-10) ease-out,
    box-shadow var(--transition-duration-10) ease-out;
  outline: none;
}

.PinturaShapePresetsPalette .PinturaButton .PinturaButtonIcon {
  width: 1em;
}

.PinturaShapePresetsPalette .PinturaButton .PinturaButtonLabel {
  font-size: 0.75em;
}

.PinturaShapePresetsPalette .PinturaButton:hover {
  box-shadow: inset 0 0 0 1px var(--color-foreground-10);
}

.PinturaShapePresetsPalette .PinturaButton[data-focus-visible] {
  background-color: var(--color-focus-50);
  box-shadow: inset 0 0 0 1px var(--color-focus-100);
}

.PinturaShapePresetsPalette .PinturaShapePresetsFlat > * + *,
.PinturaShapePresetsPalette .PinturaShapePresetsGroups > * + *,
.PinturaShapePresetsPalette .PinturaShapePresetsGroups > div > * + * {
  margin-left: 0.5em;
}

.PinturaShapePresetsGroups {
  display: flex;
}

.PinturaShapePresetsGrouped {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.PinturaShapePresetsGrouped [role="tab"] {
  white-space: nowrap;
}

.PinturaShapePresetsFlat {
  display: flex;
  align-items: center;
  justify-content: center;
}

.PinturaShapePresetsFlat > .PinturaButton {
  margin-left: 1em;
}

.PinturaShapeList {
  pointer-events: none;
  opacity: 0;
  transition: opacity var(--transition-duration-10) ease-in-out;
  position: absolute;
  top: 1em;
  left: 1em;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  z-index: 1;
  border-radius: var(--border-radius);
  color: var(--color-foreground-100);
  background: var(--color-background-70);
  box-shadow: 0 0 0 1px var(--color-foreground-5);
}

.PinturaShapeList li + li {
  margin-top: -0.3125em;
}

.PinturaShapeList[data-visible="true"] {
  opacity: 1;
}

.PinturaShapeList .PinturaColorPreview {
  width: 0.75em;
  height: 0.75em;
  margin-right: 0.25em;
  border-radius: 0.25em;
}

.PinturaShapeList .PinturaShapeListItem {
  display: flex;
  align-items: center;
  padding: 0.25em 0.5em;
  outline: none;
}

.PinturaShapeList .PinturaShapeListItem > span {
  font-size: 0.75em;
  line-height: 1.75;
  padding: 0 0.325em;
  margin-left: -0.325em;
}

.PinturaShapeList .PinturaShapeListItem[data-focus-visible] > span {
  background-color: var(--color-focus-50);
  box-shadow: inset 0 0 0 1px var(--color-focus-100);
}

.PinturaShapeList[data-visible="true"] .PinturaShapeListItem {
  pointer-events: all;
}

.PinturaShapeEditor {
  height: 100%;
  width: 100%;
  outline: none;
  touch-action: none;
}

.PinturaShapeControls {
  position: absolute !important;
  left: 0;
  top: 0;
  z-index: 3;
  pointer-events: none;
  display: flex;
  align-items: center;
}

.PinturaShapeControlsGroup {
  pointer-events: all;
  color: #eee;
  background: #222;
  box-shadow: 0 0.125em 0.25em rgba(0, 0, 0, 0.25);
  border-radius: 0.25em;
}

.PinturaShapeControlsGroup,
.PinturaShapeControlsGroup .PinturaShapeControlsGroupToggle {
  display: inline-flex;
  min-height: 1.75em;
}

.PinturaShapeControlsGroup + .PinturaShapeControlsGroup {
  margin-left: 0.5em;
}

.PinturaShapeControlsGroup .PinturaShapeControlsGroupToggle {
  position: relative;
}

.PinturaShapeControlsGroup .PinturaButton {
  outline: transparent;
}

.PinturaShapeControlsGroup .PinturaButton[data-focus-visible] {
  background-color: var(--color-focus-50);
  box-shadow: inset 0 0 0 1px var(--color-focus-100);
}

.PinturaShapeControlsGroup .PinturaButton[disabled] > span {
  opacity: 0.5;
  pointer-events: all;
  cursor: not-allowed;
}

.PinturaShapeControlsGroup > .PinturaButton {
  display: block;
  font-weight: 700;
  transition: background-color var(--transition-duration-10);
  outline: transparent;
}

.PinturaShapeControlsGroup > .PinturaButton:hover {
  background-color: hsla(0, 0%, 100%, 0.15);
}

.PinturaShapeControlsGroup > .PinturaButton .PinturaButtonLabel {
  display: block;
  font-size: 0.6875em;
  padding: 0 0.75em;
  line-height: 1.7em;
}

.PinturaShapeControlsGroup .PinturaButtonIconOnly {
  width: 1.75em;
  transition: background-color var(--transition-duration-10);
}

.PinturaShapeControlsGroup .PinturaButtonIconOnly:hover {
  background-color: hsla(0, 0%, 100%, 0.15);
}

.PinturaShapeControlsGroup > button:first-of-type {
  border-radius: 0.25em 0 0 0.25em;
}

.PinturaShapeControlsGroup > button:last-of-type {
  border-radius: 0 0.25em 0.25em 0;
}

.PinturaShapeControlsGroup > button:only-of-type {
  border-radius: 0.25em;
}

.PinturaShapeControlsGroup > * + * {
  border-left: 1px solid rgba(0, 0, 0, 0.5);
}

.PinturaShapeControlsGroup svg {
  pointer-events: none;
}

.PinturaInlineInput {
  position: absolute;
  left: 0;
  top: 0;
  margin: 0;
  padding: 0;
  transform-origin: center;
  display: flex;
}

.PinturaInlineInput:before {
  content: "";
  width: 0;
  height: var(--line-height);
}

.PinturaContentEditable {
  margin: 0;
  padding: 0;
  width: calc(100% / var(--font-scalar));
  height: calc((100% / var(--font-scalar)) - var(--bottom-inset, 0));
  overflow: hidden;
  outline: none;
  vertical-align: top;
}

.PinturaOptionsList,
.PinturaOptionsListWrapper {
  border-radius: inherit;
}

.PinturaOptionsList .PinturaListOption {
  transition: background-color var(--transition-duration-10);
  border-radius: inherit;
  position: relative;
}

.PinturaOptionsList .PinturaListOption label {
  display: block;
  margin: 0;
  border-radius: inherit;
  white-space: nowrap;
}

.PinturaOptionsList .PinturaListOption svg {
  margin-right: 0.25em;
  margin-left: -0.5em;
  min-width: 1.5em;
}

.PinturaOptionsList .PinturaListOption svg:only-child {
  margin-left: 0;
  margin-right: 0;
}

.PinturaOptionsList .PinturaListOption [slot] {
  display: flex;
  align-items: center;
  padding: 0.325em 0.75em;
}

.PinturaOptionsList .PinturaDropdownOptionLabel {
  font-size: 0.75em;
  line-height: 1.75;
  padding: 0 0.325em;
  margin-left: -0.325em;
  display: inline-flex;
  flex-direction: column;
}

.PinturaOptionsList .PinturaDropdownOptionSublabel {
  font-size: 0.75em;
  opacity: 0.5;
  margin-top: -0.5em;
}

.PinturaOptionsList .PinturaListOptionGroup > span {
  display: block;
  padding: 0.75em 1em;
  text-transform: uppercase;
  font-size: 0.75em;
  color: var(--color-foreground-50);
}

.PinturaOptionsList [data-disabled="true"] {
  color: var(--color-foreground-50);
}

.PinturaOptionsList [data-selected="false"]:not([data-disabled="true"]):hover {
  background-color: var(--color-foreground-10);
}

.PinturaOptionsList [data-selected="true"] {
  color: var(--color-foreground-100);
  background-color: var(--color-foreground-20);
}

.PinturaOptionsList [data-focus-visible] + label .PinturaDropdownOptionLabel {
  background-color: var(--color-focus-50);
  box-shadow: inset 0 0 0 1px var(--color-focus-100);
  border-radius: 0.25em;
}

.PinturaListOption label {
  margin: 0;
}

.PinturaRoot[dir="rtl"] .PinturaDropdownOptionLabel {
  margin-right: -0.325em;
  align-items: flex-end;
}

.PinturaRoot[dir="rtl"] .PinturaListOption svg {
  margin-left: -0.5em;
  margin-right: auto;
}

.PinturaRoot[dir="rtl"] .PinturaListOption svg + span {
  margin-left: 0.25em;
}

.PinturaRoot[dir="rtl"] .PinturaListOption [slot] {
  justify-content: flex-end;
}

.PinturaColorPickerPanel {
  padding: 0.5em;
  max-width: 14em;
  overflow: visible;
  touch-action: none;
}

.PinturaColorPickerPanel .PinturaEyeDropper {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-foreground-70);
  width: 1.5em;
  height: 1.5em;
  margin-left: 0.25em;
}

.PinturaColorPickerPanel .PinturaEyeDropper svg {
  width: 1em;
  height: 1em;
}

.PinturaColorPickerPanel .PinturaColorPickerButton {
  outline: transparent;
}

.PinturaColorPickerPanel .PinturaColorPickerButton[data-focus-visible] {
  box-shadow: inset 0 0 0 1px var(--color-foreground-70);
}

.PinturaColorPickerPanel .PinturaColorPreview {
  width: 1.25em;
  height: 1.25em;
  border-radius: 0.25em;
}

.PinturaColorPickerPanel .PinturaColorPreview span {
  border-radius: 0.2em;
  box-shadow: inset 0 0 0 1px var(--color-foreground-20);
}

.PinturaColorPickerPanel .PinturaColorPresets legend {
  color: var(--color-foreground-50);
  margin: 0 0 0.25em 0.3125em;
  font-size: 0.75em;
}

.PinturaColorPickerPanel .PinturaColorPresets:only-child legend {
  display: none;
}

.PinturaColorPickerPanel .PinturaColorPresetsGrid .PinturaRadioGroupOptions {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.PinturaColorPickerPanel .PinturaColorPresetsGrid .PinturaListOption {
  display: block;
  padding: 0.25em;
}

.PinturaColorPickerPanel .PinturaColorPresetsGrid .PinturaListOption label,
.PinturaColorPickerPanel .PinturaColorPresetsGrid .PinturaListOption label span {
  display: block;
  cursor: var(--button-cursor);
}

.PinturaColorPickerPanel .PinturaColorPresetsGrid input[data-focus-visible] + label .PinturaColorPreview:after {
  position: absolute;
  left: -0.3125em;
  right: -0.3125em;
  top: -0.3125em;
  bottom: -0.3125em;
  border-radius: inherit;
  pointer-events: none;
  content: "";
  background-color: var(--color-focus-50);
  box-shadow: inset 0 0 0 1px var(--color-focus-100);
}

.PinturaColorPickerPanel .PinturaSlider {
  margin-left: -0.325em;
  margin-right: -0.325em;
}

.PinturaColorPickerPanel .PinturaSlider > .PinturaSliderControl {
  position: relative;
  z-index: 2;
}

.PinturaColorPickerPanel .PinturColorPickerInputGroup {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0.75em 0 0.25em 0.3125em;
}

.PinturaColorPickerPanel .PinturaPicker {
  width: 100%;
}

.PinturaColorPickerPanel .PinturaPicker + .PinturaColorPresets {
  margin-top: 0.5em;
}

.PinturaColorPickerPanel .PinturaPicker .PinturaPickerKnob,
.PinturaColorPickerPanel .PinturaPicker .PinturaSliderKnob {
  border-radius: 9999em;
  box-shadow: inset 0 0.06125em 0.06125em rgba(0, 0, 0, 0.1), 0 0 0 0.06125em #fff,
    0 0.1875em 0.25em rgba(0, 0, 0, 0.35), 0 -0.125em 0.25em rgba(0, 0, 0, 0.1);
}

.PinturaColorPickerPanel .PinturaPicker .PinturaPickerKnob {
  pointer-events: none;
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  margin-left: -0.625em;
  margin-top: -0.625em;
  width: 1.25em;
  height: 1.25em;
  visibility: visible;
  outline: none;
}

.PinturaColorPickerPanel .PinturaPicker .PinturaPickerKnob[data-focus-visible]:after {
  position: absolute;
  left: -0.3125em;
  right: -0.3125em;
  top: -0.3125em;
  bottom: -0.3125em;
  border-radius: inherit;
  pointer-events: none;
  content: "";
  background-color: var(--color-focus-50);
  box-shadow: inset 0 0 0 1px var(--color-focus-100);
}

.PinturaColorPickerPanel .PinturaPicker .PinturaPickerKnobController {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  visibility: hidden;
}

.PinturaColorPickerPanel .PinturaPicker .PinturaSaturationPicker {
  position: relative;
  border-radius: var(--border-radius) var(--border-radius) 0 0;
  margin: -0.5em -0.5em 0.5em;
  height: 7em;
  background-image: linear-gradient(180deg, transparent, #000), linear-gradient(90deg, #fff, transparent);
}

.PinturaColorPickerPanel .PinturaPicker .PinturaHuePicker .PinturaSliderTrack {
  background-image: linear-gradient(90deg, red, #ff0, #0f0, #0ff, #00f, #f0f, red);
}

.PinturaColorPickerPanel .PinturaPicker .PinturaSliderTrack {
  box-shadow: inset 0 0 1px var(--color-foreground-50);
}

.PinturaColorPickerPanel .PinturaPicker .PinturaOpacityPicker .PinturaSliderKnob:before,
.PinturaColorPickerPanel .PinturaPicker .PinturaOpacityPicker .PinturaSliderTrack:before {
  position: absolute;
  content: "";
  display: block;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  border-radius: inherit;
  background-color: #fff;
  background-image: var(--pattern-transparent);
}

.PinturaColorPickerPanel .PinturaPicker .PinturaOpacityPicker .PinturaSliderKnob:before {
  background-position: calc(var(--slider-position) * -2px) 0;
}

.PinturaColorPickerPanel .PinturaPicker .PinturaOpacityPicker {
  margin-top: 0.5em;
}

.PinturaColorPickerPanel .PinturaPicker .PinturaColorPickerInput {
  color: var(--color-foreground-80);
  width: calc(100% - 0.625em);
  line-height: 1.75;
  text-indent: 0.5em;
  font-size: 0.875em;
  border-radius: 0.25em;
  border: 1px solid var(--color-foreground-20);
  font-variant: tabular-nums;
  text-transform: uppercase;
}

.PinturaColorPickerPanel .PinturaPicker .PinturaColorPickerInput:focus {
  outline: transparent;
  border-color: var(--color-focus-100);
}

.PinturaColorPickerPanel .PinturaPicker .PinturaColorPickerInput[disabled] {
  color: var(--color-foreground-40);
}

.PinturaPanel {
  --color-inset: hsla(0, 0%, 100%, 0.1);
  --color-fill: var(--color-foreground-5);
  top: 0;
  left: 0;
  z-index: 2147483647;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  cursor: auto;
  outline: transparent;
  box-shadow: inset 0 0 0 1px var(--color-inset), 0 0.125em 0.25em rgba(0, 0, 0, 0.25),
    0 0.125em 0.75em rgba(0, 0, 0, 0.15), 0 0.125em 0.125em rgba(0, 0, 0, 0.1);
  border-radius: var(--border-radius);
}

.PinturaPanel,
.PinturaPanelTip {
  position: absolute;
  color: var(--color-foreground-90);
  background-color: var(--color-background-100);
  background-image: linear-gradient(var(--color-fill), var(--color-fill));
  background-blend-mode: screen;
}

.PinturaPanelTip {
  --tip-mask: transparent calc(50% - 1.5px), #000 calc(50% - 1.5px);
  width: 0.5em;
  height: 0.5em;
  margin-left: -0.25em;
  margin-top: -0.25em;
  z-index: -1;
  box-shadow: inset -1px -1px 0 0 var(--color-inset);
  -webkit-mask: linear-gradient(to bottom right, var(--tip-mask));
  mask: linear-gradient(to bottom right, var(--tip-mask));
  pointer-events: none;
}

.PinturaPanelTip[data-top="true"] {
  box-shadow: inset 1px 1px 0 0 var(--color-inset);
  -webkit-mask: linear-gradient(to top left, var(--tip-mask));
  mask: linear-gradient(to top left, var(--tip-mask));
}

.PinturaContentEditable {
  white-space: pre-line !important;
  word-break: break-word !important;
  display: inline-block !important;
  -webkit-user-select: text !important;
  -moz-user-select: text !important;
  -ms-user-select: text !important;
  user-select: text !important;
}

.PinturaContentEditable[data-wrap-content="nowrap"] {
  white-space: nowrap !important;
  word-break: normal !important;
}

.PinturaContentEditable[data-wrap-content="nowrap"] br {
  display: none;
}

.PinturaContentEditable {
  -webkit-user-drag: none;
  background: transparent !important;
  margin: 0 !important;
  border-radius: 0 !important;
  max-width: none !important;
  min-width: auto !important;
  box-shadow: none !important;
}

.PinturaColorPreview {
  --color: transparent;
  color: var(--color);
  position: relative;
  pointer-events: none;
  background-color: #fff;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='8' height='8' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 0h4v4H0zm4 4h4v4H4z' fill='%23E5E5E5'/%3E%3C/svg%3E");
}

.PinturaColorPreview span {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: var(--color);
  border-radius: inherit;
}

.PinturaSlider {
  position: relative;
  --track-offset-x: 0.5em;
  --track-offset-y: 0.5em;
  --track-size: 1.5em;
  --knob-size: 1em;
  --knob-margin-top: 0em;
  --knob-margin-right: 0em;
  --knob-margin-bottom: 0em;
  --knob-margin-left: 0em;
  display: flex;
  align-items: center;
  touch-action: none;
}

.PinturaSlider[data-direction="y"] {
  flex-direction: column;
}

.PinturaSlider .PinturaSliderControl {
  position: relative;
  flex: 1;
}

.PinturaSlider > button {
  position: relative;
  z-index: 1;
  width: var(--track-size);
  height: var(--track-size);
  overflow: hidden;
  cursor: pointer;
  flex-shrink: none;
}

.PinturaSlider > button svg {
  width: 1.25em;
  height: 1.25em;
  margin: 0.125em;
  stroke-width: 0.125em;
  stroke: var(--color-foreground-50);
}

.PinturaSlider > button:first-of-type {
  order: 2;
  padding-right: 0.125em;
  margin-left: -0.325em;
}

.PinturaSlider > button:last-of-type {
  order: 1;
  padding-left: 0.125em;
  margin-right: -0.325em;
}

.PinturaSlider > .PinturaSliderControl {
  order: 2;
}

.PinturaSlider .PinturaSliderKnob {
  left: 0;
  top: 50%;
  border-radius: 9999em;
  visibility: visible;
  width: var(--knob-size);
  height: var(--knob-size);
  margin-left: calc(var(--knob-size) * -0.5);
  margin-top: calc(var(--knob-size) * -0.5);
  background: var(--knob-background);
}

.PinturaSlider .PinturaSliderKnob,
.PinturaSlider .PinturaSliderKnobController,
.PinturaSlider .PinturaSliderTrack {
  pointer-events: none;
  position: absolute;
}

.PinturaSlider .PinturaSliderTrack {
  top: var(--track-offset-y);
  bottom: var(--track-offset-y);
  left: var(--track-offset-x);
  right: var(--track-offset-x);
  background: var(--track-background);
}

.PinturaSlider .PinturaSliderKnobController {
  visibility: hidden;
  top: calc(var(--track-offset-y) + var(--knob-margin-top));
  bottom: calc(var(--track-offset-y) + var(--knob-margin-bottom));
  left: calc(var(--track-offset-x) + var(--knob-margin-left));
  right: calc(var(--track-offset-x) + var(--knob-margin-right));
}

.PinturaSlider .PinturaSliderTrack {
  border-radius: 9999em;
}

.PinturaSlider[data-direction="x"] input[type="range"] {
  height: var(--track-size);
  margin-left: var(--track-offset-x);
  width: calc(100% - (var(--track-offset-x) * 2));
}

.PinturaSlider[data-direction="y"] input[type="range"] {
  width: var(--track-size);
  margin-top: var(--track-offset-y);
  height: calc(100% - (var(--track-offset-y) * 2));
}

.PinturaSlider input[type="range"] {
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  display: block;
}

.PinturaSlider input[type="range"]::-webkit-slider-runnable-track {
  background: transparent;
}

.PinturaSlider input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  line-height: 0;
  margin: 0;
  padding: 0;
  border: none;
}

.PinturaSlider input[type="range"]::-moz-range-thumb {
  line-height: 0;
  margin: 0;
  padding: 0;
  border: none;
  visibility: hidden;
}

.PinturaSlider input[type="range"]::-ms-thumb {
  line-height: 0;
  margin: 0;
  padding: 0;
  border: none;
}

.PinturaSlider input[type="range"]::-ms-ticks-before,
.PinturaSlider input[type="range"]::-ms-tooltip {
  display: none;
}

.PinturaSlider input[type="range"]::-ms-ticks-after {
  display: none;
}

.PinturaSlider input[type="range"]::-ms-track {
  color: transparent;
  border: none;
  background: transparent;
}

.PinturaSlider input[type="range"]::-ms-fill-lower,
.PinturaSlider input[type="range"]::-ms-fill-upper {
  background: transparent;
}

.PinturaSlider input[type="range"]:focus {
  outline: transparent;
}

.PinturaSlider input[type="range"][data-focus-visible] ~ .PinturaSliderKnobController .PinturaSliderKnob:after {
  position: absolute;
  left: -0.3125em;
  right: -0.3125em;
  top: -0.3125em;
  bottom: -0.3125em;
  border-radius: inherit;
  content: "";
  background-color: var(--color-focus-50);
  box-shadow: inset 0 0 0 1px var(--color-focus-100);
  pointer-events: none;
}

.PinturaShapeManipulator {
  position: absolute;
  left: 0;
  top: 0;
  outline: none;
  touch-action: none;
  transform: translate3d(calc(var(--tx) * 1px), calc(var(--ty) * 1px), 0) rotate(var(--r)) scale(var(--sx), var(--sy));
  --size: 0.75em;
}

.PinturaShapeManipulator:not([data-control="edge"]) {
  margin-left: calc(var(--size) * (-0.5));
  margin-top: calc(var(--size) * (-0.5));
  line-height: var(--size);
  width: var(--size);
  height: var(--size);
  border-radius: 9999em;
}

.PinturaShapeManipulator:not([data-control="edge"]):after {
  position: absolute;
  left: -0.5em;
  right: -0.5em;
  top: -0.5em;
  bottom: -0.5em;
  content: "";
}

.PinturaShapeManipulator:not([data-control="edge"])[data-focus-visible]:after {
  border-radius: inherit;
  background-color: var(--color-focus-50);
  box-shadow: inset 0 0 0 1px var(--color-focus-100);
}

.PinturaShapeManipulator[data-control="point"],
.PinturaShapeManipulator[data-control="scale"] {
  background: linear-gradient(90deg, var(--color-secondary-dark), var(--color-secondary));
  box-shadow: 0 0 0 2px #fff, 0 0 4px #000;
  z-index: 3;
}

.PinturaShapeManipulator[data-control="point"][data-selected="true"]:before,
.PinturaShapeManipulator[data-control="scale"][data-selected="true"]:before {
  display: block;
  content: "";
  width: calc(var(--size) * 0.5);
  height: calc(var(--size) * 0.5);
  background: #fff;
  margin-left: calc(var(--size) * 0.25);
  margin-top: calc(var(--size) * 0.25);
  border-radius: 9999em;
}

.PinturaShapeManipulator[data-control="scale"] {
  cursor: -webkit-grab;
  cursor: grab;
}

.PinturaShapeManipulator[data-control="edge"] {
  margin-top: calc(var(--size) * (-0.5));
  height: var(--size);
  width: 1px;
  z-index: 1;
  transform-origin: 0 calc(var(--size) * 0.5);
}

.PinturaShapeManipulator[data-control="edge"][data-focus-visible] {
  background-color: var(--color-focus-50);
}

.PinturaShapeManipulator[data-control="rotate"] {
  border-radius: 9999em;
  background: linear-gradient(90deg, #fff, #ddd);
  box-shadow: 0 0 0 2px var(--color-secondary-dark), 0 0 4px #000;
  z-index: 2;
  cursor: -webkit-grab;
  cursor: grab;
}

.PinturaRoot[data-env*="chrome-"] .PinturaShapeManipulator[data-control="edge"] {
  width: calc(var(--sx) * 1px);
  transform: translate3d(calc(var(--tx) * 1px), calc(var(--ty) * 1px), 0) rotate(var(--r));
}
*/