:root {
    --Font-Serif: 'Noto Serif', serif;
    --Font-Sans: 'Oswald', sans-serif;
    --Font-Family-Sans: "Oswald", "sans-serif";
    --Font-Size-XXL-Sans: 44px;
    --Font-Size-S: 16px;
    --Font-Size-XS: 10px;
    --Font-Size-M: 20px;
    --Font-Size-L: 24px;
    --Font-Size-XL: 32px;
    --Font-Size-XXL: 44px;

}

h1,
.text-xxxxxl-serif {
    font-family: var(--Font-Serif);
    font-size: 100px;
    font-style: italic;
    font-weight: 400;
    line-height: 120%;
    /* 120px */
}

h2,
.text-xxxxl-serif {
    font-family: var(--Font-Serif);
    font-size: 84px;
    font-style: italic;
    font-weight: 400;
    line-height: 120%;
    /* 100.8px */
}

h3,
.text-xxxl-serif {
    font-family: var(--Font-Serif);
    font-size: 64px;
    font-style: italic;
    font-weight: 400;
    line-height: 100%;
    /* 64px */
}

h4,
.text-xxl-serif {
    font-family: var(--Font-Serif);
    font-size: 44px;
    font-style: italic;
    font-weight: 400;
    line-height: 120%;
    /* 52.8px */
}

h5,
.text-xl-serif {
    font-family: var(--Font-Serif);
    font-size: 32px;
    font-style: italic;
    font-weight: 400;
    line-height: 120%;
    /* 38.4px */
}

h5,
.text-l-serif {
    font-family: var(--Font-Serif);
    font-size: 24px;
    font-style: italic;
    font-weight: 400;
    line-height: 120%;
    /* 28.8px */
}

h6,
.text-m-serif {
    font-family: var(--Font-Serif);
    font-size: 20px;
    font-style: italic;
    font-weight: 400;
    line-height: 120%;
    /* 24px */
}

.text-s-serif {
    font-family: var(--Font-Serif);
    font-size: 16px;
    font-style: italic;
    font-weight: 400;
    line-height: 120%;
    /* 19.2px */
}

.text-xs-serif {
    font-family: var(--Font-Serif);
    font-size: 14px;
    font-style: italic;
    font-weight: 400;
    line-height: 120%;
    /* 16.8px */
}

.text-xxl-sans {
    font-family: var(--Font-Sans);
    font-size: 44px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    /* 52.8px */
}

.text-xxxl-sans {
    font-family: var(--Font-Sans);
    font-size: 64px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    /* 52.8px */
}

.text-xxxxl-sans {
    font-family: var(--Font-Sans);
    font-size: 72px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    /* 52.8px */
}

.text-xxxxxl-sans {
    font-family: var(--Font-Sans);
    font-size: 160px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    /* 52.8px */
}

.text-xl-sans {
    font-family: var(--Font-Sans);
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    /* 38.4px */
}

.text-l-sans {
    font-family: var(--Font-Sans);
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    /* 28.8px */
}

.text-m-sans {
    font-family: var(--Font-Sans);
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    /* 24px */
}

.text-normal {
    font-weight: 400 !important;
}

.text-s-sans {
    font-family: var(--Font-Sans);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    /* 19.2px */
}

.text-sm-sans {
    color: var(--Primary-White, #FFF);
    font-family: var(--Font-Sans);
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 140%;
}

.text-xs-sans {
    font-family: var(--Font-Sans);
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    /* 12px */
    letter-spacing: 1.2px;
    text-transform: uppercase;
}

.text-lg-body {
    font-family: var(--Font-Sans);
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    /* 28px */
}

.text-m-body {
    font-family: var(--Font-Sans);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 144%;
    /* 23.04px */
}

.text-s-body {
    font-family: var(--Font-Sans);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    /* 16.8px */
}

@media (max-width: 1920px) {
    .text-xxxxxl-serif {
        font-size: 96px;
    }

    .text-xxxxl-sans {
        font-size: 68px;
    }
}

@media (max-width: 1440px) {
    .text-xxxxxl-serif {
        font-size: 64px;
    }

    .text-xxxl-sans {
        font-size: 48px;
    }
}

@media (max-width: 834px) {
    .text-xxxxxl-serif {
        font-size: 48px;
    }

    .text-xxxxl-sans {
        font-size: 44px;
    }
}

@media (max-width: 600px) {
    .text-xxxxxl-serif {
        font-size: 44px;
    }
}

@media (max-width: 430px) {
    .text-xxxxxl-serif {
        font-size: 42px;
        line-height: 120%;
    }

    .text-xxxxl-serif {
        font-size: 44px;
        line-height: 120%;
    }

    .text-xxxl-serif {
        font-size: 40px;
        line-height: 120%;
    }

    .text-xxl-serif {
        font-size: 32px;
        line-height: 120%;
    }

    .text-xl-serif {
        font-size: 28px;
        line-height: 120%;
    }

    .text-l-serif {
        font-size: 24px;
        line-height: 120%;
    }

    .text-m-serif {
        font-size: 20px;
        line-height: 120%;
    }

    .text-s-serif {
        font-size: 16px;
        line-height: 120%;
    }

    .text-xxxxxl-sans {
        font-size: 48px;
        line-height: 120%;
    }

    .text-xxxxl-sans {
        font-size: 44px;
        line-height: 120%;
    }

    .text-xxxl-sans {
        font-size: 40px;
        line-height: 120%;
    }

    .text-xxl-sans {
        font-size: 32px;
        line-height: 120%;
    }

    .text-xl-sans {
        font-size: 24px;
        line-height: 120%;
    }

    .text-l-sans {
        font-size: 20px;
        line-height: 120%;
    }

    .text-m-sans {
        font-size: 16px;
        line-height: 120%;
    }

    .text-s-sans {
        font-size: 14px;
        line-height: 120%;
    }

    .text-xs-sans {
        font-size: 10px;
        line-height: 120%;
        font-weight: 600;
        letter-spacing: 1.2px;
        text-transform: uppercase;
    }
}