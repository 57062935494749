.pintura-container {
  container-type: inline-size;
  container-name: pintura-container;
}

@container pintura-container (min-width: 120px) {
  .PinturaRootWrapper.oai-editor.oai-editor-mode-crop-modal  {
    height: 60cqh;
    width: 100cqw;
  }
}