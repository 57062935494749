@import "../../styles/colors.css";

.app_footer {
    min-height: 172px;
    background-color: var(--Primary-Yellow);
    background-image: url(../../../public/images/footer_bg_1.svg);
    background-position-x: right;
    background-repeat: no-repeat;
    padding: 57px 293px 0 60px;
    display: flex;
    /*justify-content: space-between;*/
    justify-items: center;
    align-items: center;
    margin-top: auto;
    gap: 32px;
}

.mobile_pixel_block {
    display: none;
}

.footer_logo {
    height: 76px;
    width: 67px;
}

.footer_links {
    flex-grow: 1;
    align-items: center;
}

@media (max-width: 834px) {
    .app_footer {
        background-image: none;
        display: flex;
        flex-direction: column;
        padding: 0;
    }

    .mobile_pixel_block {
        margin-top: 16px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
        background-color: var(--Primary-Dark-Gray);
        /*max-width: calc(100vw - 20);*/
    }

    .footer_pixel {
        width: calc(100vw/8 - 2.5px);
        height: calc(100vw/8 - 2.5px);
    }

    .footer_logo {
        height: 56px;
        width: 50px;
        margin-bottom: 16px;
    }
}