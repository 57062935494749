.image-card {
    width: 360px;
    /* height: 460px; */
    flex-shrink: 0;
    background-repeat: no-repeat;
    background-size: 360px 460px;
    position: relative;
    overflow: hidden;
}

.image-card:hover .image-card-inner,
.image-card:focus .image-card-inner {
    transform: scale(1.025);
    transition: all 0.15s;
}

.image-card-inner {
    width: 100%;
    height: 100%;
    background-color: black;
    /* fallback color */
    background-position: center;
    background-size: cover;
}

.image-card .info-block {
    position: absolute;
    left: 40px;
    bottom: 16px;
}

.info-block .category {
    color: var(--Primary-White, #fff);
    font-family: var(--Font-Serif);
    font-size: 20px;
    font-style: italic;
    font-weight: 500;
    line-height: 20px;
    /* 100% */
    margin-bottom: 8px;
    text-transform: capitalize;
}

.info-block .name {
    color: var(--Primary-White, #fff);
    font-family: var(--Font-Sans);
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 38px;
    /* 118.75% */
    text-transform: capitalize;
    margin-bottom: 12px;
}

.action-icon {
    position: absolute;
    right: 20px;
    top: 20px;
    border-radius: 100%;
    /* background: transparent; */
    height: 51px;
    width: 51px;
    border: 2px solid #fff;
}

.subheader {
    font-weight: 600;
    padding-bottom: 24px;
    /* padding-top: 52px; */
}

.clip-path-triangle {
    clip-path: polygon(0 0, 100% 0, 0 100%);
}
