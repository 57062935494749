.how_works_section_1 {
    position: relative;
    padding: 195px 0 148px 0;
}

.text_block {
    margin: auto;
}

.text_block ul {
    list-style: disc;
    padding-top: 32px;
}

.text_block ul.no_padding {
    padding-top: 0;
    padding-left: 24px;
}


.text_block h1 {
    margin: 14px 0px 14px 0px;
}

.text_block .pill_button {
    display: inline-block;
    padding: 16px 30px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 70px;
    border: 2px solid var(--Primary-Dark-Gray, #161618);
}

.how_works_section_1 .pixel_block {
    position: absolute;
    right: 0;
    bottom: 0;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    align-content: flex-end;
}

.how_works_section_1_and_half {
    background: var(--Primary-White);
    display: flex;
    padding: 72px 100px 72px 16px;
}

.how_works_section_1_and_half .bullet_number {
    background-color: var(--Secondary-Medium-Gray, #202124);
}

.how_works_section_2 {
    background: var(--Primary-White);
    display: flex;
    padding: 72px 100px 72px 16px;
}

.bullet_number {
    width: 160px;
    height: 160px;
    min-width: 120px;
    min-height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Noto Serif";
    font-size: 100px;
    font-style: italic;
    font-weight: 400;
    line-height: 120%;
    /* 120px */
    color: white;
}

.how_works_section_2 .bullet_number {
    background-color: var(--Secondary-Pink);
}

.how_works_section_2 .text_block {
    margin-right: 32px;
}

.how_works_section_3 {
    background: var(--Primary-White);
    display: flex;
    padding: 72px 100px 72px 16px;
}

.how_works_section_3 .bullet_number {
    background-color: var(--Secondary-Blue);
}

.how_works_section_4 {
    background: var(--Primary-White);
    display: flex;
    padding: 72px 100px 72px 16px;
}

.how_works_section_4 .bullet_number {
    background-color: var(--Secondary-Red);
}

.image_block {
    position: relative;
    min-width: 443px;
    display: flex;
    justify-content: center;
}

.image_block img {
    z-index: 5;
}

.background_pixel {
    width: 443px;
    height: 443px;
    position: absolute;
    top: 100px;
    z-index: 0;

}

.how_works_section_5 {
    background: var(--Primary-Yellow);
    display: flex;
    flex-direction: column;
    gap: 76px;
    padding: 72px 100px 72px 16px;
    align-items: center;
}

.how_works_section_5 .text_block {
    text-align: center;
}

@media (max-width: 1600px) {
    .bullet_number {
        width: 120px;
        height: 120px;
    }
}

@media (max-width: 834px) {

    .how_works_section_2,
    .how_works_section_3,
    .how_works_section_1_and_half,
    .how_works_section_4 {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .how_works_section_3,
    .how_works_section_1_and_half {
        flex-direction: column-reverse;
    }


    .how_works_section_5 .text_block {
        margin: auto;
        width: 100%;
        text-align: center;
    }

    .bullet_number {
        width: 80px;
        height: 80px;
    }

    .how_works_section_2 {
        padding: 32px 16px 0px 16px;
    }

    .how_works_section_2 .text_block {
        margin: 0 16px 0 16px;
        width: auto;
    }

    .how_works_section_3 {
        padding: 0px 16px 0px 16px;
    }

    .how_works_section_3 .text_block {
        margin: 0 16px 0 16px;
        width: auto;
    }

    .how_works_section_4 {
        padding: 0px 16px 0px 16px;
    }

    .how_works_section_4 .text_block {
        margin: 0 16px 0 16px;
        width: auto;
    }

    .how_works_section_5 {
        padding: 72px 16px 72px 16px;
        flex-direction: column;
    }
}