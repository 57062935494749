@import "../styles/colors.css";

.lic-prop-card {
  width: 360px;
  height: 460px;
  flex-shrink: 0;
  background-repeat: no-repeat;
  background-size: 360px 460px;
  position: relative;
  overflow: hidden;
}
.lic-prop-card:hover .lic-prop-card-inner,
.lic-prop-card:focus .lic-prop-card-inner {
  transform: scale(1.025);
  transition: all .15s;
}
.lic-prop-card-inner {
    width: 100%;
  height: 100%;
  background-color: black; /* fallback color */
  background-position: center;
  background-size: cover;
}
.lic-prop-card .price-pill{
    position: absolute;
    top: 20px;
    right: 25px;
    display: inline-flex;
    padding: 6px 12px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 20px;
    border: 1px solid #FFF;
    background: transparent;

    color: #FFF;
    text-align: right;

    /* Sans/XS */
    font-family: var(--Font-Sans);
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 12px */
    letter-spacing: 1.2px;
    text-transform: uppercase;
}
.price-pill.unregistered{
    background: var(--Primary-Yellow, #FDFD43);
    color: var(--Primary-Dark-Gray, #141414);
}
.lic-prop-card .info-block{
  position: absolute;
  left: 40px;
  bottom: 16px;
}
.info-block .talent-profession{
  color: var(--Primary-White, #FFF);
  font-family: var(--Font-Serif);
  font-size: 20px;
  font-style: italic;
  font-weight: 500;
  line-height: 20px; /* 100% */
  margin-bottom:8px;
  text-transform: capitalize;
}
.info-block .talent-name{
  color: var(--Primary-White, #FFF);
  font-family: var(--Font-Sans);
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 38px; /* 118.75% */
  text-transform: capitalize;
  margin-bottom: 12px;
}
.info-block .social-iconbar{
    display: flex;
    padding-top: 4px;
    align-items: flex-start;
    gap: 16px;
}
.social-iconbar .social-follower{
    display:flex;
    gap: 6px;
    align-items: center;
}
.social-follower .follower-count{
    color: var(--Primary-White, #FFF);
    font-family: var(--Font-Sans);
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 166.667% */
    letter-spacing: 1.2px;
}
.social-follower.profile{
    display: flex;
    flex-direction: column;
    gap: 16px;
}
.social-follower.profile .follower-count{
    font-size: 24px;
    font-weight: 600;
    line-height: 120%; /* 28.8px */
}
@media only screen and (max-width: 320px){
    .lic-prop-card {
        width: 320px;
        height: 450px;
        background-size: 320px 407px;
    }
}
@media only screen and (min-width: 768px){
    .lic-prop-card {
        width: 381px;
        height: 487px;
        background-size: 381px 487px;
    }
}
@media only screen and (min-width: 1024px){
    .lic-prop-card {
        width: 341px;
        height: 436px;
        background-size: 341px 436px;
    }
}
@media only screen and (min-width: 1440px){
    .lic-prop-card {
        width: 360px;
        height: 460px;
        background-size: 360px 460px;
    }
}
@media only screen and (min-width: 1920px){
    .lic-prop-card {
        width: 480px;
        height: 613px;
        background-size: 480px 613px;
    }
}
@media only screen and (min-width: 2500px){
    .lic-prop-card {
        width: 500px;
        height: 638px;
        background-size: 500px 638px;
    }
}