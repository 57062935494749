.home_page {}

/*.home_page nav{*/
/*    position: static;*/
/*    background-color: transparent;*/
/*}*/
.home_container {
    /*display: grid;*/
    /*grid-template-columns: 1fr 1fr;*/
}

.protect_block {
    width: 50%;
    display: inline-block;
    vertical-align: top;
}

.inner_protect_block {
    background-color: var(--Primary-Yellow);
    padding-top: 15vw;
    padding-left: 10vw;
    padding-bottom: 78px;
    padding-right: 10vw;

}

.protect_block h1 {
    padding-bottom: 16px;
}

.block_2 {
    display: inline-block;
    width: 50%;
    background-color: var(--Primary-White);
    padding: 140px 140px 0px 140px;
    min-height: 65vw
}

.block_2 .primary_image {
    position: relative;
    /* border-radius: 40px; */
    box-shadow: 0px 0px 9px 9px rgba(0, 0, 0, 0.04);
}

.pixel_sm {
    width: calc(5vw);
    height: calc(5vw);
}

.pixel_md {
    width: calc(10vw);
    height: calc(10vw);
    max-width: 120px;
    max-height: 120px;
}

.pixel_lg {
    width: calc(15vw);
    height: calc(15vw);
    max-width: 180px;
    max-height: 180px;
}

.background_pixel_1 {
    width: 443px;
    height: 443px;
    position: absolute;
    top: 300px;
    z-index: 0;
}

/* .background_pixel {
    width: 443px;
    height: 443px;
    position: absolute;
    top: 100px;
    z-index: 0;
} */

.pixel_red {
    background-color: var(--Secondary-Red);
}

.pixel_pink {
    background-color: var(--Secondary-Pink);
}

.pixel_yellow {
    background-color: var(--Primary-Yellow);
}

.pixel_black {
    background-color: var(--Primary-Dark-Gray);
}

.pixel_dark_gray {
    background-color: var(--Secondary-Medium-Gray, #202124);
}

.pixel_blue {
    background-color: var(--Secondary-Blue);
}

.pixel_white {
    background-color: var(--Primary-White);
}

.pixel_transparent {
    background-color: transparent;
}

.pixel_1 {
    position: absolute;
    top: calc(-5vw);
    left: 0px;
}

.pixel_2 {
    position: absolute;
    top: 0px;
    left: calc(-5vw);
}

.pixel_3 {
    position: absolute;
    top: calc(5vw);
    right: 0px;
    z-index: 2;
}

.pixel_4 {
    position: absolute;
    top: calc(10vw);
    right: calc(-5vw);
}

.pixel_5 {
    position: absolute;
    bottom: calc(-10vw);
}

.pixel_6 {
    position: absolute;
    bottom: calc(-20vw);
    left: calc(10vw);
}

.pixel_7 {
    position: absolute;
    bottom: calc(-7.5vw);
    right: calc(-5vw);
    display: flex;
    justify-content: center;
    align-items: center;
}

.pixel_7 img {
    width: 50%;

}

.block_3 {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

img.block_3_header_left_img_1 {
    width: 15vw;
    height: 15vw;
    /*padding-left: 10vw;*/
    display: inline-block;
}

.block_3_header_left {
    display: flex;
    text-align: center;
    justify-content: space-between;
}

.block_3_header_left .block_3_pixel_group {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    height: 4vw;
}

.block_3_header_right {
    text-align: center;
}

.block_3_pixel_group_2 {
    width: 4vw;
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: auto;
    margin-top: 20px;
}

.block_3_content {
    width: 50%;
    text-align: center;
    margin: auto;
}

@media (max-width: 430px) {
    .block_3_content {
        font-size: 28px !important;
    }
}

/***************************
  Start block 4
***************************/
.block_4 {
    display: flex;
    margin: 48px 0 48px 0;
}

.block_4_pixel_group {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.block_4_right {}

.block_4_image_group {
    position: relative;
}

.block_4_image_group .badge_overlay {
    position: absolute;
    top: 16px;
    left: 16px;
}

.block_4_image_group .block_4_pixel_1 {
    position: absolute;
    left: -2.5vw;
    bottom: -2.5vw;
}

.block_4_image_main {
    width: 16vw;
    height: 21vw;
}

/***************************
  End block 4
***************************/

/***************************
  Start block 5
***************************/
.block_5 {
    display: flex;
    width: 100%;
}

.block_5_left {
    width: 50%;
    display: flex;
    justify-content: center;
}

.block_5_right {
    width: 50%;
    display: flex;
    justify-content: space-around;
}

.block_5_image_group_1 {
    position: relative;
    width: 240px;
    height: 240px;
    margin-bottom: 3vw;
}

.block_5_image_group_1 .block_5_image_1_badge_overlay {
    position: absolute;
    top: 16px;
    right: 16px;
}

.block_5_image_group_1 .block_5_image_group_1_pixel_1 {
    position: absolute;
    right: -6vw;
    bottom: 0;
}

.block_5_image_group_1 .block_5_image_group_1_pixel_2 {
    position: absolute;
    right: -3vw;
    bottom: -3vw;
}

.block_5_image_group_2 {
    position: relative;
    width: 200px;
    margin-top: 6vw;
}

.block_5_image_group_2 .block_5_image_2_badge_overlay {
    position: absolute;
    top: 16px;
    right: 16px;
}

.block_5_image_group_2 .block_5_image_group_2_pixel_1 {
    position: absolute;
    left: -6vw;
    top: -6vw;
}

/***************************
  End block 5
***************************/
/***************************
  Start block 6
***************************/
.block_6 {
    column-count: 2;
    column-gap: 0;
    margin-top: 32px;
    background-color: var(--Primary-White);
}

.subblock {
    margin: 0;
    display: grid;
    grid-template-rows: 1fr auto;
    break-inside: avoid;
    padding: 120px;
}

.subblock ul {
    list-style: disc;
}

.block_6 .subblock .block_title {
    font-size: 140px;
}

.subblock_p0 {
    padding: 0;
    display: flex;
    justify-content: center;
    background-color: var(--Primary-Dark-Gray)
}

.subblock_2 {
    padding: 120px 0px 0px 0px;
    display: flex;
    justify-content: flex-end;
    background-color: var(--Primary-White)
}

.block_6_image_group_1 {
    position: relative;
    width: calc(200px + 10vw);
    height: calc(200px + 5vw);
    margin-bottom: 6vw;
}

.block_6_image_group_1_pixel_1 {
    position: absolute;
    left: -5vw;
    bottom: 0;
}

.block_6_image_group_1_pixel_2 {
    position: absolute;
    left: -10vw;
    bottom: 0;
}

.subblock ul {
    margin-top: 32px;
}

.subblock li {
    padding: 0px 0;
    margin-left: 16px;
}

.block_6_image_group_2 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}


/***************************
  End block 6
***************************/
/***************************
    Start block 7
**************************/
.block_7 {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.block_7 button {
    padding-top: 200px;
}

.block_7_left {
    display: flex;
    justify-content: center;
    align-items: center;
}

.block_7_left_content {
    width: 25vw;
}

.block_7_image_group_2 {
    position: relative;
}

.block_7_image_group_2_pixel_1 {
    position: absolute;
    left: -3vw;
    bottom: 6vw;
}

/***************************
    End block 7
**************************/
/***************************
    Start block 8
*************************/
.block_8 {
    padding: 48px 48px 48px 48px;
}

.news_article {
    display: grid;
    grid-template-columns: .15fr .85fr;
    margin: 1em 0 0 0;
    gap: 2em;
}

.news_publication {
    color: var(--Primary-Dark-Gray, #161618);
    font-family: var(--Font-Sans, "Noto Serif");
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    /* 24px */
    letter-spacing: 4.8px;
    text-transform: uppercase;
}

.news_title a {
    color: var(--Primary-Dark-Gray, #161618);
    font-family: var(--Font-Serif, "Noto Serif");
    font-size: 24px;
    font-style: italic;
    font-weight: 400;
    line-height: 120%;
    /* 67.2px */
}

.news_title a:hover {
    text-decoration: underline;

}


@media (max-width: 1920px) {
    .block_2 {
        /* padding: 100px 80px 78px 80px; */
        min-height: 65vw
    }

    .pixel_sm {
        width: calc(3vw);
        height: calc(3vw);
    }

    .pixel_md {
        width: calc(6vw);
        height: calc(6vw);
    }

    .pixel_lg {
        width: calc(9vw);
        height: calc(9vw);
    }

    .pixel_1 {
        position: absolute;
        top: calc(-3vw);
        left: 0px;
    }

    .pixel_2 {
        position: absolute;
        top: 0px;
        left: calc(-3vw);
    }

    .pixel_3 {
        position: absolute;
        top: calc(3vw);
        right: 0px;
        z-index: 2;
    }

    .pixel_4 {
        position: absolute;
        top: calc(6vw);
        right: calc(-3vw);
    }

    .pixel_5 {
        position: absolute;
        bottom: calc(-6vw);
    }

    .pixel_6 {
        position: absolute;
        bottom: calc(-12vw);
        left: calc(6vw);
    }

    .pixel_7 {
        position: absolute;
        bottom: calc(-4.5vw);
        right: calc(-3vw);
        display: flex;
        justify-content: center;
        align-items: center;
    }

    img.block_3_header_left_img_1 {
        padding-left: 0;
    }

    .block_6_image_group_1 {
        width: calc(200px + 6vw);
        height: calc(200px + 3vw);
        margin-bottom: 6vw;
    }

    .block_6_image_group_1_pixel_1 {
        position: absolute;
        left: -3vw;
        bottom: 0;
    }

    .block_6_image_group_1_pixel_2 {
        position: absolute;
        left: -6vw;
        bottom: 0;
    }

    .block_6 .subblock .block_title {
        font-size: 100px;
    }
}

@media (max-width: 1140px) {
    .block_2 {
        padding: 140px 40px 78px 40px;
        min-height: 65vw
    }

    .pixel_sm {
        width: calc(2vw);
        height: calc(2vw);
    }

    .pixel_md {
        width: calc(4vw);
        height: calc(4vw);
    }

    .pixel_lg {
        width: calc(6vw);
        height: calc(6vw);
    }

    .pixel_1 {
        position: absolute;
        top: calc(-2vw);
        left: 0px;
    }

    .pixel_2 {
        position: absolute;
        top: 0px;
        left: calc(-2vw);
    }

    .pixel_3 {
        position: absolute;
        top: calc(2vw);
        right: 0px;
        z-index: 2;
    }

    .pixel_4 {
        position: absolute;
        top: calc(4vw);
        right: calc(-2vw);
    }

    .pixel_5 {
        position: absolute;
        bottom: calc(-4vw);
    }

    .pixel_6 {
        position: absolute;
        bottom: calc(-8vw);
        left: calc(4vw);
    }

    .pixel_7 {
        position: absolute;
        bottom: calc(-3vw);
        right: calc(-2vw);
        display: flex;
        justify-content: center;
        align-items: center;
    }

    img.block_3_header_left_img_1 {
        padding-left: 0;
    }

    .block_6_image_group_1 {
        width: calc(200px + 4vw);
        height: calc(200px + 2vw);
        margin-bottom: 6vw;
    }

    .block_6_image_group_1_pixel_1 {
        position: absolute;
        left: -2vw;
        bottom: 0;
    }

    .block_6_image_group_1_pixel_2 {
        position: absolute;
        left: -4vw;
        bottom: 0;
    }

    .block_6 .subblock .block_title {
        font-size: 84px;
    }

}

@media (max-width: 834px) {
    .home_container {}

    .protect_block {
        width: 100%;

    }

    .block_2 {
        width: 100%;
    }

    img.block_3_header_left_img_1 {
        margin-left: 0;
    }

    .block_3_content {
        width: 100%;
        padding: 16px;
    }

    .block_4_left {
        display: none;
    }

    .block_4_right {
        display: none;
    }

    .block_6 {
        column-count: 1;
    }

    .subblock_p0 {
        display: none;
    }

    .subblock_2 {
        /*display: none;*/
        padding: 60px 0 0 0 !important;
    }

    .subblock {
        padding: 68px
    }

    .block_6 .subblock .block_title {
        font-size: 100px;
    }
}

@media (max-width: 430px) {
    .block_6 .subblock {
        padding: 20px;
    }

    .block_6 .subblock .block_title {
        font-size: 52px;
        max-width: 95vw;
    }

    .block_6 .subblock p {
        max-width: 95vw;
    }
}